// React
import React, { Component } from "react";
import { Linking, StyleSheet, View } from "react-native";

// Types
import { NavigationType } from "@custom-types/NavigationType";
import { NotificationModel } from "@custom-types/NotificationModel";

// Base
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import RegularText from "@base/RegularText";
import Row from "@base/Row";

import RadiusButton from "@base/RadiusButton";
import { NotificationService } from "@core/services/NotificationService";
import i18n from "@i18n/i18n";
import { showModalBottom } from "@store/actions/global";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { format } from "date-fns";

interface Props {
    notification: NotificationModel;
    navigation: NavigationType;
    hideDate?: boolean;
}

const { t } = i18n;

export class NotificationCard extends Component<Props> {
    constructor(props: Props) {
        super(props);
        this.updateReadNotification = this.updateReadNotification.bind(this);
        this.showNotification = this.showNotification.bind(this);
    }

    getDescription(full?) {
        const description = this.props.notification?.description || null;
        const urlRegex = /https?:\/\/[^\s$.?#].[^\s]*/g;
        const parts = description.split(urlRegex);
        const links = description.match(urlRegex);

        if (description && links) {
            return (
                <Row style={[styles.row, { paddingTop: 5 }]}>
                    <RegularText numberOfLines={full ? null : 2} fontSize={full ? 14 : 12} style={styles.left}>
                        {parts.map((part, index) => (
                            <RegularText fontSize={full ? 14 : 12} key={index}>
                                {part}
                                {links[index] && (
                                    <RegularText
                                        onPress={() => Linking.openURL(links[index])}
                                        fontSize={full ? 14 : 12}
                                        color={getColorOpacity(colors.blue, 0.85)}
                                    >
                                        {links[index]}
                                    </RegularText>
                                )}
                            </RegularText>
                        ))}
                    </RegularText>
                </Row>
            );
        }

        return (
            <Row style={[styles.row, { paddingTop: 5 }]}>
                <RegularText numberOfLines={full ? null : 2} fontSize={full ? 14 : 12} style={styles.left}>
                    {description}
                </RegularText>
            </Row>
        );
    }

    updateReadNotification() {
        NotificationService.getInstance().updateReadNotification(this.props.notification._id);
    }

    showNotification() {
        this.updateReadNotification()
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <View style={{ paddingBottom: 60 }}>
                        <Card>
                            <Row style={[styles.row, { marginBottom: 20 }]}>
                                <View
                                    style={{
                                        paddingVertical: 2,
                                        paddingHorizontal: 4,
                                        backgroundColor: colors.secondary,
                                        borderRadius: 5,
                                    }}
                                >
                                    <RegularText color={colors.white} fontSize={10}>
                                        {format(new Date(this.props.notification.createdAt), "MMM dd, yyyy")}
                                    </RegularText>
                                </View>
                            </Row>
                            <Row style={[styles.row, { marginBottom: 10 }]}>
                                <BoldText fontSize={16}>{this.props.notification.title}</BoldText>
                            </Row>
                            {this.props.notification?.description && this.getDescription(true)}
                        </Card>
                    </View>
                ),
            }),
        );
    }

    render() {
        return (
            <Card
                style={this.props.notification.isRead && styles.read}
                onPress={this.props.notification?.description?.length < 115 ? this.updateReadNotification : null}
            >
                {this.props.notification.title && (
                    <Row style={[styles.row]}>
                        <BoldText fontSize={15} style={styles.left} numberOfLines={2}>
                            {this.props.notification.title}
                        </BoldText>
                    </Row>
                )}
                {this.props.notification?.description && this.getDescription()}
                {this.props.notification.createdAt && (
                    <Row style={[styles.row, { justifyContent: "space-between", paddingTop: 10 }]}>
                        {this.props.hideDate ? (
                            <View></View>
                        ) : (
                            <RegularText fontSize={12} style={styles.left}>
                                {format(new Date(this.props.notification.createdAt), "MMMM dd")}
                            </RegularText>
                        )}
                        {this.props.notification?.description?.length > 110 && (
                            <RadiusButton
                                title={t("see_more")}
                                style={{ paddingVertical: 2 }}
                                fontSize={12}
                                onPress={this.showNotification}
                            />
                        )}
                    </Row>
                )}
            </Card>
        );
    }
}

const isUpdated = (prevProps, nextProps) => {
    return prevProps == nextProps;
};

export default React.memo(NotificationCard, isUpdated);

const styles = StyleSheet.create({
    row: {
        width: "100%",
    },

    left: {
        flex: 1,
    },
    right: {
        minWidth: 1,
    },
    read: {
        opacity: 0.5,
    },
});
