import CarouselBase from "@base/CarouselBase";
import Placeholder from "@base/Placeholder";
import PressableBase from "@base/PressableBase";
import Row from "@base/Row";
import ImageBase from "@components/image/ImageBase";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import BannersService from "@core/services/BannersService";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import { Banner } from "@custom-types/BannerModel";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import { loading, ready, showSnackbar } from "@store/actions/global";
import { setButtonActionsType } from "@store/actions/wallet";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import * as Linking from "expo-linking";
import React, { Component } from "react";
import { Dimensions, Platform, StyleSheet, View, ViewStyle } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    banners: Array<Banner>;
    initializing: boolean;
}

interface State {
    currentIndex: number;
}

const { t } = i18n;
const { width } = Dimensions.get("window");

class _BannersSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPress = this.onPress.bind(this);
        this.openChat = this.openChat.bind(this);
        this.openExternalLink = this.openExternalLink.bind(this);
        this.openXoConnect = this.openXoConnect.bind(this);
        this.state = {
            currentIndex: 0,
        };
    }

    onPress(item: Banner) {
        if (item?.action == "chat") {
            this.openChat(item?.chatUser || "");
            return;
        }

        if (item?.action == "buy") {
            if (!ModuleControlService.getInstance().isServiceEnabled(Services.buyService)) {
                return;
            }
            this.navigateToWallet(ButtonActionsType.BUY);
            return;
        }

        if (item?.action == "sell") {
            if (!ModuleControlService.getInstance().isServiceEnabled(Services.sellService)) {
                return;
            }
            this.navigateToWallet(ButtonActionsType.SELL);
            return;
        }

        switch (true) {
            case item?.xoConnect:
                this.openXoConnect(item);
                break;
            case item.externalLink:
                this.openExternalLink(item);
                break;
            default:
                return;
        }
    }

    openXoConnect(item) {
        this.props.navigation.navigate(HomeNavigatorScreens.XoConnectScreen.routeName, {
            ...item,
        });
    }

    openExternalLink(item) {
        if (Platform.OS == "web") {
            window.open(item.url, "_blank");
        } else {
            Linking.openURL(item.url);
        }
    }

    async navigateToWallet(type: ButtonActionsType) {
        store.dispatch(loading());
        this.props.navigation.navigate(TabsNavigatorScreens.Wallets.routeName);
        store.dispatch(setButtonActionsType(type));
        setTimeout(() => {
            this.props.navigation.navigate("Wallets", { screen: "SelectCurrencyButtons" });
            store.dispatch(ready());
        }, 500);
    }

    async openChat(client_id) {
        try {
            store.dispatch(loading());
            var status = await SocialNetworkService.getInstance().contactUser(client_id);
            if (status == "redirect") {
                this.props.navigation.navigate("Messages", {
                    screen: "Chat",
                });
            }
            store.dispatch(ready());
        } catch (e) {
            store.dispatch(ready());
            store.dispatch(
                showSnackbar({
                    type: "ERROR",
                    message: t("an_error_has_occurred"),
                })
            );
        }
    }

    renderBanner = ({ item }) => {
        return (
            <PressableBase
                style={{
                    flex: 1,
                    borderRadius: settings.cardRadius,
                    overflow: "hidden",
                    marginRight:
                        (this.state.currentIndex == 0 || this.state.currentIndex + 1 == this.props.banners.length) &&
                        10,
                    marginLeft:
                        this.state.currentIndex != 0 && this.state.currentIndex + 1 != this.props.banners.length && 10,
                }}
                onPress={() => this.onPress(item)}
            >
                <ImageBase style={{ height: 80 }} uri={item.image.carousel} autoSize={true}></ImageBase>
            </PressableBase>
        );
    };

    onScroll = (event) => {
        if (Platform.OS != "web") {
            const scrollPosition = event.nativeEvent.contentOffset.x;
            const currentIndex = Math.round(scrollPosition / width);
            this.setState({ currentIndex });
        }
    };

    indexRight = (index) => {
        if (Platform.OS == "web") {
            this.setState({ currentIndex: index });
        }
    };

    indexLeft = (index) => {
        if (Platform.OS == "web") {
            this.setState({ currentIndex: index });
        }
    };

    render() {
        return (
            <>
                {this.props.initializing ? (
                    <View style={this.props.containerStyle}>
                        <Placeholder containerStyles={{ padding: 0, paddingRight: 20 }} width={"100%"} height={150} />
                        <Row style={{ justifyContent: "center" }}>
                            <Placeholder width={10} height={6} radius={5} />
                            <Placeholder width={10} height={6} radius={5} />
                            <Placeholder width={10} height={6} radius={5} />
                        </Row>
                    </View>
                ) : (
                    <View>
                        {this.props.banners?.length > 0 && (
                            <>
                                <View style={this.props.containerStyle}>
                                    <CarouselBase
                                        autoScroll={true}
                                        data={this.props.banners}
                                        keyExtractor={(item: any) => item.id}
                                        renderItem={this.renderBanner}
                                        horizontal={true}
                                        pagingEnabled={Platform.OS == "web" ? false : true}
                                        showsHorizontalScrollIndicator={false}
                                        onScroll={this.onScroll}
                                        scrollEventThrottle={16}
                                        maxToRenderPerBatch={10}
                                        bannerIndexRight={(index: number) => this.indexRight(index)}
                                        bannerIndexLeft={(index: number) => this.indexLeft(index)}
                                    />
                                </View>
                                {this.props.banners.length > 1 && (
                                    <View style={styles.pagination}>
                                        {this.props.banners.map((_, index) => (
                                            <View
                                                key={index}
                                                style={[
                                                    styles.dot,
                                                    this.state.currentIndex === index
                                                        ? {
                                                              backgroundColor: getColorOpacity(
                                                                  colors.floatButtonBackground,
                                                                  0.6
                                                              ),
                                                          }
                                                        : {
                                                              backgroundColor: getColorOpacity(
                                                                  colors.floatButtonBackground,
                                                                  0.2
                                                              ),
                                                          },
                                                ]}
                                            />
                                        ))}
                                    </View>
                                )}
                            </>
                        )}
                    </View>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        overflow: "hidden",
    },
    pagination: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
    },
    dot: {
        width: 20,
        height: 5,
        borderRadius: 5,
        margin: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        banners: state.banners?.banners,
        initializing: state.banners?.initializing,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const BannersSection = connect(mapStateToProps, mapDispatchToProps)(_BannersSection);

export default BannersSection;
