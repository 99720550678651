import "./global";
import React from "react";
import { StatusBar } from "expo-status-bar";
import useCachedResources from "./src/hooks/useCachedResources";
import Init from "@components/init/Init";
import Constants from "expo-constants";
import { LogBox, View } from "react-native";
import { colors } from "@styles/globalStyles";
import "react-native-reanimated";
import * as SplashScreen from "expo-splash-screen";

SplashScreen.preventAutoHideAsync();

export default function App() {
    const isLoadingComplete = useCachedResources();
    var theme = Constants.expoConfig?.extra?.theme || "dark";
    LogBox.ignoreAllLogs(true);

    if (!isLoadingComplete) {
        return null;
    } else {
        return (
            <View style={{ backgroundColor: colors.gradientTo, flex: 1, height: "100%" }}>
                <StatusBar style={theme == "dark" ? "light" : "dark"} />
                <Init />
            </View>
        );
    }
}
