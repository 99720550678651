import BotCard from "@base/BotCard";
import CircleButton from "@base/CircleButton";
import Container from "@base/Container";
import { FloatButton } from "@base/FloatButton";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import RegularTextInput from "@base/RegularTextInput";
import WarningCard from "@base/WarningCard";
import Header from "@components/header/Header";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { Client } from "@custom-types/Client";
import { HeaderType } from "@custom-types/HeaderType";
import Post from "@custom-types/Post";
import i18n from "@i18n/i18n";
import { SocialNetworkNavigatorScreens } from "@navigation/SocialNetworkNavigator";
import { loading, ready, showPopupMessage } from "@store/actions/global";
import { setDiscoverPosts } from "@store/actions/social.actions";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { Camera } from "expo-camera";
import * as ImageManipulator from "expo-image-manipulator";
import * as ImagePicker from "expo-image-picker";
import React, { Component } from "react";
import { Dimensions, Image, Platform, ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import SocialNetworkService from "./service/SocialNetworkService";

interface Props {
    navigation: any;
    mainPosts: Array<Post>;
    client: Client;
}

interface State {
    img: any;
    view: string;
    description: any;
    hashtag: any;
    mention: any;
    camera: any;
    setCamera: boolean;
    alert: boolean;
    message: string;
    imageHight: number;
    imageWidth: number;
}

const { t } = i18n;

export class _NewPostScreen extends Component<Props, State> {
    socialNetworkService: SocialNetworkService;
    constructor(props: Props) {
        super(props);
        this.state = {
            img: null,
            view: Platform.OS == "web" ? "galery" : "camera",
            description: "",
            hashtag: [],
            mention: [],
            camera: null,
            setCamera: true,
            alert: false,
            message: "",
            imageHight: 300,
            imageWidth: Dimensions.get("window").width,
        };
        this.socialNetworkService = SocialNetworkService.getInstance();
        store.dispatch(ready());
    }

    async requestPermission() {
        await Camera.requestCameraPermissionsAsync();
        const permission = await Camera.getCameraPermissionsAsync();
        await ImagePicker.requestMediaLibraryPermissionsAsync();

        if (permission?.status !== "granted" && permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission") }));
            await Camera.requestCameraPermissionsAsync();
        }

        if (permission?.status !== "granted" && !permission.canAskAgain) {
            store.dispatch(showPopupMessage({ type: "ERROR", message: t("camera_permission_null") }));
        }
    }

    setImage = (width, height) => {
        const autoHight = width / this.state.imageWidth;
        this.setState({ imageHight: height / autoHight });
    };

    selectImage = async (camera?: boolean) => {
        this.requestPermission();

        let image: any = camera
            ? await ImagePicker.launchCameraAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  //   allowsEditing: true,
                  base64: false,
                  quality: 1,
              })
            : await ImagePicker.launchImageLibraryAsync({
                  mediaTypes: ImagePicker.MediaTypeOptions.Images,
                  //   allowsEditing: true,
                  base64: false,
                  quality: 1,
              });

        let pickerResult = image?.assets[0];

        if (pickerResult?.width > 1080) {
            pickerResult = await ImageManipulator.manipulateAsync(pickerResult?.uri, [{ resize: { width: 1080 } }], {
                compress: 0.8,
                format: ImageManipulator.SaveFormat.JPEG,
            });
        }

        if (pickerResult?.height > 1080) {
            pickerResult = await ImageManipulator.manipulateAsync(pickerResult?.uri, [{ resize: { height: 1080 } }], {
                compress: 0.8,
                format: ImageManipulator.SaveFormat.JPEG,
            });
        }

        if (pickerResult?.height > pickerResult?.width + pickerResult?.width / 2) {
            this.setState({ alert: true, message: t("image_hight") });
        } else {
            this.setState({ img: pickerResult?.uri, view: "img", alert: false });
            Image.getSize(this.state.img ? this.state.img : "", (width, height) => this.setImage(width, height));
        }
    };

    changeInputValue(value) {
        var split = value
            .replace(",", "")
            .replace("\n", " ")
            .split(" " || "#" || "@");
        var hashtagFilter = split.filter((e) => e.charAt(0) == "#");
        var mention = split.filter((e) => e.charAt(0) == "@");
        var hashtags = [];
        hashtagFilter.map((hashtag) => hashtags.push({ name: hashtag.replace("#", "") }));

        this.setState({
            ...this.state,
            description: value,
            hashtag: hashtags,
            mention: mention,
            alert: false,
        });
    }

    takePhoto(photo) {
        this.setState({ img: photo.uri, view: "galery", alert: false });

        Image.getSize(photo.uri ? photo.uri : "", (width, height) => this.setImage(width, height));
    }

    returnCamera = () => {
        this.setState({
            ...this.state,
            setCamera: true,
            view: "camera",
        });
    };

    createPost = async () => {
        if (!this.state.img) {
            this.setState({ alert: true, message: t("image_warning") });
        }

        if (!this.state.description && this.state.img) {
            this.setState({ alert: true, message: t("description_warning") });
        }

        if (this.state.img && this.state.description) {
            store.dispatch(loading());

            let post = {
                image: this.state.img,
                description: this.state.description,
                type: "standard",
                hashtags: this.state.hashtag,
            };
            let newPost = await this.socialNetworkService.newPost(post);
            if (newPost) {
                store.dispatch(setDiscoverPosts([]));
                var posts = await this.socialNetworkService.getPostsDiscover();
                if (posts) {
                    this.props.navigation.navigate(SocialNetworkNavigatorScreens.Discover.routeName);
                }
                this.props.navigation.navigate(SocialNetworkNavigatorScreens.Discover.routeName);
            }
            store.dispatch(ready());
        }
    };

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("new_post")} type={HeaderType.Light} navigation={this.props.navigation} />

                <Container style={{ flex: 1, paddingBottom: 50 }}>
                    <ScrollView showsHorizontalScrollIndicator={false}>
                        {this.state.img ? (
                            <View
                                style={{
                                    marginTop: 10,
                                    maxWidth: 600,
                                    width: "100%",
                                    marginHorizontal: "auto",

                                    overflow: "hidden",
                                }}
                            >
                                <View style={{}}>
                                    <ImageBase
                                        uri={this.state.img}
                                        autoSize={true}
                                        style={{
                                            borderRadius: settings.cardRadius,
                                            resizeMode: "cover",
                                        }}
                                    />

                                    <View
                                        style={{
                                            position: "absolute",
                                            alignSelf: "center",
                                            bottom: -25,
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            width: this.state.imageWidth / 4,
                                            height: 50,
                                        }}
                                    >
                                        <PressableBase
                                            onPress={() => this.selectImage(false)}
                                            style={{ marginRight: 10 }}
                                        >
                                            <Icon name="images" size={20} color={colors.white} style={styles.icon} />
                                        </PressableBase>
                                        <PressableBase onPress={() => this.selectImage(true)}>
                                            <Icon name="camera" size={20} color={colors.white} style={styles.icon} />
                                        </PressableBase>
                                    </View>
                                </View>

                                <View
                                    style={{
                                        flex: 1,
                                        paddingTop: 30,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <RegularTextInput
                                        maxLength={1200}
                                        multiline={true}
                                        align={"left"}
                                        onChangeText={(text) => this.changeInputValue(text)}
                                        value={this.state.description}
                                        placeholder={"Add a description"}
                                        style={{
                                            backgroundColor: colors.shadow,
                                            paddingHorizontal: 15,
                                            paddingVertical: 20,
                                            overflow: "hidden",
                                            borderRadius: settings.cardRadius,
                                        }}
                                    />
                                </View>
                            </View>
                        ) : (
                            <View>
                                <View style={{}}>
                                    <BotCard message={t("new_post_message")}></BotCard>
                                </View>

                                <View
                                    style={{
                                        marginTop: 10,
                                        maxWidth: 600,
                                        width: "80%",
                                        height: 200,
                                        marginHorizontal: "auto",
                                        alignSelf: "center",
                                        alignItems: "center",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                    }}
                                >
                                    <CircleButton
                                        title={t("gallery")}
                                        icon="images"
                                        iconSize={26}
                                        onPress={() => this.selectImage(false)}
                                        style={{ marginHorizontal: 10 }}
                                    />
                                    <CircleButton
                                        title={t("camera")}
                                        icon="camera"
                                        iconSize={26}
                                        onPress={() => this.selectImage(true)}
                                        style={{ marginHorizontal: 10 }}
                                    />
                                </View>
                            </View>
                        )}

                        <View>
                            <View
                                style={{
                                    marginTop: 10,
                                    paddingHorizontal: 5,
                                    maxWidth: 600,
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    paddingBottom: 25,
                                }}
                            >
                                {this.state.hashtag.map((hashtag) => {
                                    return (
                                        <RegularText fontSize={12} style={styles.hashtag} key={hashtag.name}>
                                            #{hashtag.name}
                                        </RegularText>
                                    );
                                })}
                            </View>

                            {this.state.alert && (
                                <View>
                                    <WarningCard message={this.state.message} />
                                </View>
                            )}
                        </View>
                    </ScrollView>

                    {!this.state.alert && <FloatButton onPress={this.createPost} iconName={"right"} />}
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mainPosts: state.social.mainPosts,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const NewPostScreen = connect(mapStateToProps, mapDispatchToProps)(_NewPostScreen);

export default NewPostScreen;

const styles = StyleSheet.create({
    icon: {
        height: 40,
        width: 40,
        borderRadius: 20,
        padding: 10,
        backgroundColor: colors.secondary,
        overflow: "hidden",
    },

    button: {
        position: "absolute",
        height: 55,
        width: 55,
        borderRadius: 30,
        backgroundColor: colors.secondary,
        bottom: 10,
        right: 10,
        justifyContent: "center",
        alignItems: "center",
    },

    hashtag: {
        backgroundColor: getColorOpacity(colors.grey, 0.6),
        color: colors.white,
        marginRight: 10,
        alignSelf: "center",
        paddingHorizontal: 10,
        paddingVertical: 5,
        borderRadius: 5,
        textAlign: "center",
        overflow: "hidden",
    },

    mention: {
        color: colors.secondary,
        marginHorizontal: 10,
    },
});
