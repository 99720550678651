import ExperiencesMainScreen from "@screens/experiences/ExperiencesMainScreen";
import ScannerExperiencesScreen from "@screens/scanner/ScannerExperiencesScreen";
import { ExperienceNavigator } from "./ExperienceNavigator";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import { NotificationsNavigator } from "./NotificationsNavigator";
import { ProfileNavigator } from "./ProfileNavigator";

const ExperiencesNavigatorScreens = {
    ExperiencesMain: {
        routeName: "ExperiencesMain",
        title: "Experiences",
        component: ExperiencesMainScreen,
    },
    Experience: {
        routeName: "Experience",
        title: "Experience",
        component: ExperienceNavigator,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
    Notifications: {
        routeName: "Notifications",
        title: "Notifications",
        component: NotificationsNavigator,
    },
    ScannerExperiences: {
        routeName: "ScannerExperiences",
        title: "Scanner Experiences",
        component: ScannerExperiencesScreen,
    },
};

const ExperiencesNavigator = NavigationInstantiator(ExperiencesNavigatorScreens);

export { ExperiencesNavigator, ExperiencesNavigatorScreens };
