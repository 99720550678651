import { colors } from "@styles/globalStyles";
import React from "react";
import Lottie from "react-lottie";
import { StyleSheet } from "react-native";

interface Props {
    source: "loader";
    loop?: boolean;
    height?: number;
    width?: number;
    colorFilter?: boolean;
    speed?: number;
}

export default function LottieBase(props: Props) {
    return (
        <Lottie
            options={{
                loop: props.loop || false,
                autoplay: true,
                animationData: props.source || "",

                rendererSettings: {
                    // preserveAspectRatio: "xMidYMid slice",
                },
            }}
            speed={props.speed || 1}
            colorFilters={
                props.colorFilter
                    ? [
                          {
                              keypath: "**",
                              color: colors.white,
                          },
                      ]
                    : []
            }
            height={props.height || 200}
            width={props.width || 200}
        />
    );
}

const styles = StyleSheet.create({});
