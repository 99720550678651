import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { Camera, CameraView } from "expo-camera";
import { colors, settings } from "@styles/globalStyles";
import InlineButton from "@base/InlineButton";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import Currency from "@core/currencies/Currency";
import { connect } from "react-redux";
import * as Clipboard from "expo-clipboard";
import Container from "@base/Container";
import CircleButton from "@base/CircleButton";
import store from "@store/index";
import { hideModal, loading, ready, showModal, showPopup } from "@store/actions/global";
// import WalletConnectService from "@core/services/WalletConnectService";
import POAPService from "@core/services/POAPService";
import { nameSocialLengthHelper } from "@utils/helpers/social/social.helper";
import Wallet from "@core/wallet/Wallet";
import ProfileService from "@core/services/ProfileService";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { setPOAPs } from "@store/actions/profile.actions";
import POAP from "@custom-types/POAP";
import { ProfileData } from "@screens/profile2.0/components/ProfileHeader";
import WebViewBase from "@components/webview/WebViewBase";
import { color } from "react-native-elements/dist/helpers";
import { Client } from "@custom-types/Client";
import ExperienceService from "@core/services/ExperienceService";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import { REDEEMABLE } from "@custom-types/ExpercienceModel";
import { Route } from "@react-navigation/native";
import { updateRedeemable } from "@store/actions/experiences.actions";


interface Props {
    navigation: NavigationType;
    route: Route<
        string,
        {
            redeemable: REDEEMABLE;
        }
    >;
    client: Client;
    profiles: Array<POAP>;
}

interface State {
    scanned: boolean;
    redeemmible: REDEEMABLE;
}

const { t } = i18n;

export class _ScannerExperiencesScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);

        this.handleBarCodeScanned = this.handleBarCodeScanned.bind(this);
        this.pasteCode = this.pasteCode.bind(this);
        this.parseData = this.parseData.bind(this);
        this.getRedeemable = this.getRedeemable.bind(this);
        this.getGate = this.getGate.bind(this);
        this.state = {
            scanned: false,
            redeemmible: this.props.route.params?.redeemable || null,
        };
    }

    async componentDidMount() {
        const { status } = await Camera.requestCameraPermissionsAsync();
    }

    async pasteCode() {
        const pasteText: string = await Clipboard.getStringAsync();

        if (pasteText !== undefined) {
            this.parseData(pasteText);
        } else {
            store.dispatch(showPopup({ type: "ERROR", message: " " }));
        }
    }

    parseData(data: string) {

        // this.setState({ scanned: true });
        // const actionData = data?.split(":");

        // switch (actionData[0]) {
        //     case "redeemable":
        //         return this.getRedeemable(actionData[1]);
        //     case "gate":
        //         return this.getGate(actionData[1]);

        //     default:
        //         store.dispatch(
        //             showPopup({
        //                 type: "ERROR",
        //                 message: t("an_error_has_occurred"),
        //             }),
        //         );
        // }
        //this.getRedeemable(result.data);
    }
    async getRedeemable(id?: string) {
        store.dispatch(loading());

        const redeemable = await ExperienceService.getInstance().getRedeemable(id);
        if (redeemable) {
            this.props.navigation.navigate(ExperiencesNavigatorScreens.ExperiencesMain.routeName);
            // this.props.navigation.navigate(ExperiencesNavigatorScreens.Redeemable.routeName, {
            //     redeemable: redeemable,
            // });
        }
        store.dispatch(ready());
    }

    async getGate(id?: string) {
        store.dispatch(loading());
        const redeemables: Array<REDEEMABLE> = await ExperienceService.getInstance().getGate(id);
        store.dispatch(ready());
        if (redeemables?.length == 0) {
            store.dispatch(
                showPopup({
                    type: "ERROR",
                    message: t("redeemables_error_message"),
                }),
            );
            this.goBack();
        }
        if (this.state.redeemmible && redeemables?.length) {

            this.showUseRedeemableModal(this.state.redeemmible, id);
        }
    }

    showUseRedeemableModal(redeemable: REDEEMABLE, gate: string) {
        this.goBack();
        store.dispatch(
            showModal({
                title: nameSocialLengthHelper(redeemable.redeemableOption.name, 50),
                avatar: redeemable.redeemableOption.image.carousel || redeemable.redeemableOption.image.cover,
                question: t("modal_reddemable_question", { type: redeemable.redeemableOption.type }),
                btnTitle: t("confirm"),
                onPress: () => this.confirmUseRedeemable(redeemable._id, gate),
            }),
        );
    }

    async confirmUseRedeemable(redeemable_id: string, gate_id: string) {
        store.dispatch(loading());
        store.dispatch(hideModal());
        const resp = await ExperienceService.getInstance().useRedeemable(redeemable_id, gate_id);
        if (resp) {
            store.dispatch(updateRedeemable(resp));
        }
        store.dispatch(ready());
    }

    handleBarCodeScanned(result: any) {
        if (this.state.scanned) return;
        this.parseData(result.data);
    }

    goBack = () => {
        this.props.navigation.goBack();
    };

    render() {
        return (
            <View style={styles.wrapper}>
                <CameraView
                    onBarcodeScanned={this.handleBarCodeScanned}
                    style={styles.scanner}
                >
                    <Container
                        style={{
                            flex: 1,
                            position: "absolute",
                            top: 20,
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        }}
                    >
                        <CircleButton style={{ top: 0 }} iconSize={26} icon={"clipboard"} onPress={this.pasteCode} />
                    </Container>
                    <InlineButton title={t("back")} onPress={this.goBack} style={styles.button} />
                    <View style={styles.maskWrapper}>
                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={styles.maskTopLeft}></View>
                            <View style={styles.maskTopRight}></View>
                        </View>

                        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
                            <View style={styles.maskBottomLeft}></View>
                            <View style={styles.maskBottomRight}></View>
                        </View>
                    </View>
                </CameraView>
            </View>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        profiles: state.profile.profiles,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ScannerExperiencesScreen = connect(mapStateToProps, mapDispatchToProps)(_ScannerExperiencesScreen);

export default ScannerExperiencesScreen;

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        backgroundColor: colors.background,
        padding: 0,
        paddingHorizontal: 0,
        paddingLeft: 0,
        paddingStart: 0,
        marginHorizontal: 0,
        marginLeft: 0,
        marginStart: 0,
    },
    scanner: {
        backgroundColor: "black",
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        margin: 0,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        height: "100%",
    },
    button: {
        position: "absolute",
        bottom: 50,
    },
    maskWrapper: {
        position: "absolute",
        width: 300,
        height: 300,
        justifyContent: "space-between",
    },
    maskTopLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderTopLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskTopRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderTopRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderTopColor: "white",
        borderTopWidth: 2,
    },
    maskBottomLeft: {
        position: "relative",
        top: 0,
        bottom: 0,
        left: 0,
        width: 50,
        height: 50,
        borderBottomLeftRadius: 10,
        borderLeftColor: "white",
        borderLeftWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
    maskBottomRight: {
        position: "relative",
        top: 0,
        bottom: 0,
        right: 0,
        width: 50,
        height: 50,
        borderBottomRightRadius: 10,
        borderRightColor: "white",
        borderRightWidth: 2,
        borderBottomColor: "white",
        borderBottomWidth: 2,
    },
});
