import Header from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { Route } from "@react-navigation/native";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import ProfileService from "@core/services/ProfileService";
import { Client } from "@custom-types/Client";
import { ProfileSections } from "@custom-types/ProfileType";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { connect } from "react-redux";
import ProfileHeader, { ProfileData } from "./components/ProfileHeader";
import NFTsSection from "./components/NFTsSection";
import POAPsSection from "./components/POAPsSection";
import { PostsSection } from "./components/PostsSection";

const { t } = i18n;

interface Props {
    navigation: NavigationType;
    route: Route<string, { clientID?: string; profileSection?: ProfileSections }>;
    loading: boolean;
    client: Client;
    profiles: any;
}

interface State {
    clientID: string;
    isOwner: boolean;
    client: ProfileData;
    switchProfileType: ProfileSections;
    loading: boolean;
}

export class _ProfileMainScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.init = this.init.bind(this);
        this.getProfileHeader = this.getProfileHeader.bind(this);
        this.swithCallBack = this.swithCallBack.bind(this);

        this.state = {
            clientID: this.props.route?.params?.clientID || this.props.client?._id,
            isOwner: false,
            client: null,
            switchProfileType: this.props.route.params?.profileSection || ProfileSections.socialNetwork,
            loading: true,
        };
    }

    componentDidMount = async () => {
        await this.init();
    };

    async componentDidUpdate(props) {
        if (this.props.route?.params?.clientID?.length && this.props.route?.params?.clientID != this.state.clientID) {
            this.setState({ clientID: this.props.route?.params?.clientID || this.props.client?._id }, () =>
                this.init(),
            );
        }
        if (props.client?._id != this.props.client?._id) {
            await this.init();
        }
    }

    async init() {
        this.setState({
            isOwner: this.props.client?._id == this.state.clientID,
            loading: true,
        });

        const setReducerProfile = await ProfileService.getInstance().setFullProfile(this.state.clientID);
        this.setState({ loading: false });
    }

    swithCallBack(profileType: ProfileSections) {
        this.setState({ switchProfileType: profileType });
    }

    getProfileHeader() {
        return (
            <ProfileHeader
                isOwner={this.state.isOwner}
                profile={this.props.profiles[this.state.clientID]?.profile}
                navigation={this.props.navigation}
                postCounter={this.props.profiles[this.state.clientID]?.POSTs?.totalDocs || 0}
                nftCounter={this.props.profiles[this.state.clientID]?.NFTs?.length || 0}
                poapCounter={this.props.profiles[this.state.clientID]?.POAPs?.length || 0}
                profileType={this.state.switchProfileType}
                swithCallBack={this.swithCallBack}
            />
        );
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    navigation={this.props.navigation}
                    {...this.props}
                    title={" "}
                    type={HeaderType.Light}
                    rightBtn={{
                        onPress: () => {
                            this.props.navigation.navigate(ProfileNavigatorScreens.ProfileOptions.routeName, {
                                isOwner: this.state.isOwner,
                                client: this.props.profiles[this.state.clientID]?.profile,
                            });
                        },
                        iconType: "custom",
                        icon: "three-dots-vertical",
                    }}
                />

                {this.props.profiles[this.state.clientID]?.profile && (
                    <View style={{ flex: 1 }}>
                        {this.props.profiles[this.state.clientID]?.profile.isPrivateProfile &&
                        !this.props.profiles[this.state.clientID]?.profile.isFollower &&
                        !this.state.isOwner ? (
                            <View style={{ flex: 1 }}>{this.getProfileHeader()}</View>
                        ) : (
                            <View style={{ flex: 1 }}>
                                {this.state.switchProfileType == ProfileSections.socialNetwork &&
                                    !this.props.profiles[this.state.clientID]?.profile?.blocked && (
                                        <PostsSection
                                            loadingBackground={this.state.loading}
                                            posts={this.props.profiles[this.state.clientID]?.POSTs?.posts}
                                            postExtra={this.props.profiles[this.state.clientID]?.POSTs}
                                            cliendID={this.state.clientID}
                                            getProfileHeader={this.getProfileHeader}
                                            isAvatarAndAliasSet={true}
                                            isOwner={this.state.isOwner}
                                            navigation={this.props.navigation}
                                            {...this.props}
                                        />
                                    )}

                                {this.state.switchProfileType == ProfileSections.nftProfile &&
                                    !this.props.profiles[this.state.clientID]?.profile?.blocked && (
                                        <NFTsSection
                                            loadingBackground={this.state.loading}
                                            clientId={this.state.clientID}
                                            isOwner={this.state.isOwner}
                                            NFTs={this.props.profiles[this.state.clientID]?.NFTs}
                                            getProfileHeader={this.getProfileHeader}
                                            navigation={this.props.navigation}
                                        />
                                    )}
                                {this.state.switchProfileType == ProfileSections.poapsProfile &&
                                    !this.props.profiles[this.state.clientID]?.profile?.blocked && (
                                        <POAPsSection
                                            loadingBackground={this.state.loading}
                                            isOwner={this.state.isOwner}
                                            POAPs={this.props.profiles[this.state.clientID]?.POAPs}
                                            getProfileHeader={this.getProfileHeader}
                                            navigation={this.props.navigation}
                                        />
                                    )}
                            </View>
                        )}
                    </View>
                )}
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        loading: state.global.loading,
        profiles: state.profile.profiles,
        POSTs: state.profile.POSTs,
        NFTs: state.profile.NFTs,
        POAPs: state.profile.POAPs,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ProfileMainScreen = connect(mapStateToProps, mapDispatchToProps)(_ProfileMainScreen);

export default ProfileMainScreen;
