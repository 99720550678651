import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { ModuleControlService, SkinType } from "@core/services/ModuleControlService";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import React, { Component } from "react";
import { connect } from "react-redux";
import HomeScreenBase from "./screens/HomeScreenBase";
import HomeScreenCrypto from "./screens/HomeScreenCrypto";
import HomeScreenEvents from "./screens/HomeScreenEvents";
import HomeScreenSports from "./screens/HomeScreenSports";
import HomeScreenTokenizedAssets from "./screens/HomeScreenTokenizedAssets";

interface Props {
    navigation: NavigationType;
    client: Client;
    fiatCurrency?: FiatCurrency;
}

interface State {}

const { t } = i18n;

export class _HomeScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <ScreenWrapper>
                {ModuleControlService.getInstance().getSkin(SkinType.base) && (
                    <HomeScreenBase navigation={this.props.navigation} />
                )}
                {ModuleControlService.getInstance().getSkin(SkinType.events) && (
                    <HomeScreenEvents navigation={this.props.navigation} />
                )}

                {ModuleControlService.getInstance().getSkin(SkinType.sports) && (
                    <HomeScreenSports navigation={this.props.navigation} />
                )}
                {ModuleControlService.getInstance().getSkin(SkinType.assets) && (
                    <HomeScreenTokenizedAssets navigation={this.props.navigation} />
                )}
                {ModuleControlService.getInstance().getSkin(SkinType.crypto) && (
                    <HomeScreenCrypto navigation={this.props.navigation} />
                )}
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        messages: state.chat,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const HomeScreen = connect(mapStateToProps, mapDispatchToProps)(_HomeScreen);

export default HomeScreen;
