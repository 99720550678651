import store from "@store/index";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import { getEnv } from "@utils/helpers/global/global";
import { loading, ready } from "@store/actions/global";
import i18n from "@i18n/i18n";
import { ModuleControlService, Modules } from "./ModuleControlService";
import { setBanners, setInitializing } from "@store/actions/banners.actions";
const { t } = i18n;

export default class BannersService {
    private static instance: BannersService;

    constructor() {}

    static getInstance(): BannersService {
        if (!BannersService.instance) {
            BannersService.instance = new BannersService();
        }
        return BannersService.instance;
    }

    async getBanners() {
        if (!ModuleControlService.getInstance().isModuleEnabled(Modules.bannersModule)) {
            store.dispatch(setInitializing(false));
            return;
        }
        try {
            const url = `${getEnv("API_URL")}banners?__sort=-sort&__limit=100`;
            const response = await axios.get(url, {
                headers: await ApiService.getAuthHeaders(),
            });
            if (response?.data) {
                store.dispatch(setInitializing(false));
                store.dispatch(setBanners(response.data?.docs));
            }
            return response.data?.docs;
        } catch {
            store.dispatch(setInitializing(false));
        }
    }
}
