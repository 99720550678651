import i18n from "i18n-js";
import en from "./languages/en.json";
import es from "./languages/es.json";
import tr from "./languages/tr.json";
import pt from "./languages/pt.json";
import fr from "./languages/fr.json";
import "moment/locale/es";
import "moment/locale/tr";
import "moment/locale/pt";
import "moment/locale/fr";
import * as Localization from "expo-localization";


i18n.translations = {
    en: en,
    es: es,
    tr: tr,
    pt: pt,
    fr: fr
};

i18n.default_locale = "en";
i18n.locale = Localization.locale;
i18n.fallbacks = true;



export default i18n;
