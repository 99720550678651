import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { HeaderType } from "@custom-types/HeaderType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Header from "@components/header/Header";
import Container from "@base/Container";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ExperienceModuleType, ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { ProfileSections } from "@custom-types/ProfileType";
import ExperienceService from "@core/services/ExperienceService";
import { Platform, RefreshControl, ScrollView, View } from "react-native";
import WalletSection from "../sections/WalletSection";
import RedeemableTypeSection from "@screens/experiences/components/RedeemableTypeSection";
import { RedeemableType } from "@custom-types/ExpercienceModel";
import ProfileService from "@core/services/ProfileService";
import { HomeNavigatorScreens } from "@navigation/HomeNavigator";
import NFTsSection from "../sections/NFTsSection";
import MembershipsSection from "@screens/experiences/components/MembershipsSection";
import ExperiencesSection from "@screens/experiences/components/ExperiencesSection";
import { setUnreadMessagesBase } from "@utils/helpers/chat/chat-service.helper";
import NotificationsSection from "../components/NotificationsSection";
import { CustomHomeComponent } from "@custom/CustomHomeComponent";
import PostsSection from "../components/PostsSection";
import SocialNetworkService from "@screens/social/service/SocialNetworkService";
import MerchantSection from "@screens/experiences/components/MerchantSection";
import AssetsSection from "@screens/experiences/components/AssetsSection";
import BannersSection from "../components/BannersSection";
import MessagesSection from "../components/MessagesSection";
import ShareService from "@core/services/ShareService";
import POAPsSection from "../sections/POAPsSection";
import BlogSection from "../components/BlogSection";
import { BlogService } from "@core/services/BlogService";
import ActivitySection from "@screens/activity/ActivitySection";
import ActivityService from "@core/services/ActivityService";
import BannersService from "@core/services/BannersService";
import DappsSection from "../sections/DappsSection";
import DappsService from "@core/services/DappsService";
import store from "@store/index";
import { loading, ready, showPopup } from "@store/actions/global";
import Placeholder from "@base/Placeholder";
import Row from "@base/Row";

interface Props {
    navigation: NavigationType;
    client: Client;
    messages: Array<any>;
}

interface State {
    showSearch: boolean;
    loading: boolean;
}

const { t } = i18n;

export class _HomeScreenBase extends Component<Props, State> {
    socialNetworkService: any;
    constructor(props: Props) {
        super(props);
        this.init = this.init.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.state = {
            showSearch: false,
            loading: false,
        };
    }

    async componentDidMount() {
        this.init();
    }

    async init() {
        try {
            ShareService.getInstance().handleLinking(this.props.navigation);
            BannersService.getInstance().getBanners();
            ActivityService.getInstance().getActivity(true);
            ExperienceService.getInstance().getExperiences();
            ExperienceService.getInstance().getRedeemables();
            ExperienceService.getInstance().getAssets();
            ExperienceService.getInstance().getMemberships();
            BlogService.getInstance().getBlogs();
            DappsService.getInstance().getAllDapps(true);
            // ProfileService.getInstance().setFullProfile(this.props.client?._id);
            SocialNetworkService.getInstance().getPostsDiscover();
            if (ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive)) {
                await ExperienceService.getInstance().getMerchants();
            }
        } catch (e) {}
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.messages !== this.props.messages) {
            setUnreadMessagesBase();
        }
    }

    onRefresh() {
        this.init();
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(HomeNavigatorScreens.Profile.routeName, {
                                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                                params: {
                                    profileSection: ModuleControlService.getInstance().isModuleEnabled(
                                        Modules.nftsModule
                                    )
                                        ? ProfileSections.nftProfile
                                        : ProfileSections.socialNetwork,
                                },
                            });
                        },
                        uri:
                            this.props.client &&
                            this.props.client?.profileImagePath &&
                            this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                    rightBtn={
                        Platform.OS == "web"
                            ? [
                                  {
                                      onPress: () => {
                                          this.onRefresh();
                                      },
                                      icon: "refresh",
                                      size: 22,
                                  },
                                  {
                                      onPress: () => {
                                          this.props.navigation.navigate(HomeNavigatorScreens.Notifications.routeName);
                                      },
                                      icon: "bell",
                                      size: 22,
                                  },
                              ]
                            : [
                                  {
                                      onPress: () => {
                                          this.props.navigation.navigate(HomeNavigatorScreens.Notifications.routeName);
                                      },
                                      icon: "bell",
                                      size: 22,
                                  },
                              ]
                    }
                    {...this.props}
                />
                <Container style={{ flex: 1, paddingRight: 0 }}>
                    <ScrollView
                        showsVerticalScrollIndicator={false}
                        refreshControl={<RefreshControl refreshing={this.state.loading} onRefresh={this.onRefresh} />}
                    >
                        <CustomHomeComponent />
                        <BannersSection containerStyle={{ paddingVertical: 15 }} navigation={this.props.navigation} />
                        <MessagesSection containerStyle={{ paddingVertical: 15 }} {...this.props}></MessagesSection>
                        <WalletSection
                            containerStyle={{ paddingRight: 20, paddingVertical: 15 }}
                            {...this.props}
                        ></WalletSection>

                        <NotificationsSection
                            containerStyle={{ paddingVertical: 15, paddingRight: 20 }}
                            {...this.props}
                        ></NotificationsSection>

                        {ModuleControlService.getInstance().getActiveExperienceModuleType(
                            ExperienceModuleType.exclusive
                        ) && (
                            <MerchantSection
                                navigation={this.props.navigation}
                                containerStyle={{ paddingTop: 10, paddingBottom: 25 }}
                            ></MerchantSection>
                        )}

                        <ActivitySection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15, paddingRight: 20 }}
                        />

                        <ExperiencesSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                        ></ExperiencesSection>

                        <MembershipsSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                        ></MembershipsSection>

                        <RedeemableTypeSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                            type={RedeemableType.TICKET}
                        />
                        <RedeemableTypeSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                            type={RedeemableType.VOUCHER}
                        />

                        <AssetsSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                        ></AssetsSection>

                        <POAPsSection
                            containerStyle={{ paddingVertical: 15 }}
                            key={"POAPs"}
                            navigation={this.props.navigation}
                        ></POAPsSection>
                        <NFTsSection
                            containerStyle={{ paddingVertical: 15 }}
                            navigation={this.props.navigation}
                        ></NFTsSection>

                        <DappsSection
                            navigation={this.props.navigation}
                            containerStyle={{ paddingVertical: 15 }}
                        ></DappsSection>

                        <BlogSection containerStyle={{ paddingVertical: 15 }} navigation={this.props.navigation} />

                        <PostsSection
                            containerStyle={{ paddingVertical: 15 }}
                            navigation={this.props.navigation}
                        ></PostsSection>
                    </ScrollView>
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        messages: state.chat,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const HomeScreenBase = connect(mapStateToProps, mapDispatchToProps)(_HomeScreenBase);

export default HomeScreenBase;
