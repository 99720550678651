import React, { Component } from "react";
import { ActivityIndicator, Platform, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import { SkypeIndicator } from "react-native-indicators";
import SemiBoldText from "@base/SemiBold";
import RegularText from "@base/RegularText";

interface Props {
    navigation: NavigationType;
}

interface State {
    expirationDate: string;
}

const { t } = i18n;

class _ExpirationComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            expirationDate: ''
        };
    }

    render() {
        return (
            <Container style={{ flex: 1 }}>
                <View style={{ marginBottom: 20 }}>
                    <SemiBoldText fontSize={18} color={colors.grey} align="center">
                        {t('processing_payment')}
                    </SemiBoldText>
                    <View style={{ height: 80, alignItems: "center", justifyContent: "center", marginVertical: 20 }}>
                        {Platform.OS == "web" ? (
                            <ActivityIndicator size={50} color={colors.complementary} />
                        ) : (
                            <SkypeIndicator
                                size={50}
                                animationDuration={2000}
                                color={colors.complementary}
                            />
                        )}
                    </View>
                    <View>
                        <RegularText style={{ marginBottom: 20 }} align="center" fontSize={16}>
                            {t('payment_state')}
                        </RegularText>
                        <RegularText style={{ marginBottom: 20 }} align="center" fontSize={16}>
                            {t('ticket_state')}
                        </RegularText>
                    </View>
                </View>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.experiences.experiences.docs,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExpirationComponent = connect(mapStateToProps, mapDispatchToProps)(_ExpirationComponent);

export default ExpirationComponent;

const styles = StyleSheet.create({
    filterContainer: {
        width: '100%',
        paddingVertical: 10
    },
    dateCard: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 15,
        paddingVertical: 14,
        marginTop: 5
    },
    tag: {
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        backgroundColor: colors.complementary,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginTop: 10
    }
});
