import CardButton from "@base/CardButton";
import Container from "@base/Container";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyCard from "@components/cards/CurrencyCard";
import { Header } from "@components/header/Header";
import Icon from "@components/icons";
import Balance from "@components/wallet/Balance";
import WalletsButtons from "@components/wallet/WalletsButtons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { ModuleControlService, Modules, Services } from "@core/services/ModuleControlService";
import { WalletService } from "@core/services/WalletService";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { ProfileSections } from "@custom-types/ProfileType";
import { ListHeaderButton } from "@custom/ListHeaderButton";
import i18n from "@i18n/i18n";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { setRedirection } from "@store/actions/global";
import { selectCurrency } from "@store/actions/wallet";
import store from "@store/index";
import { colors } from "@styles/globalStyles";
import React, { Component } from "react";
import { BackHandler, FlatList, Platform, RefreshControl, StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    totalBalance: number;
    fiatCurrency: FiatCurrency;
    selectCurrency: (id: any) => void;
    syncing: boolean;
    client: Client;
    redirection: string;
}

interface State {
    refreshing: boolean;
    filterCurrencies: boolean;
}

const { t } = i18n;

export class _WalletsScreen extends Component<Props, State> {
    focusListener: any;
    constructor(props: Props) {
        super(props);
        this.setFilterCurrencies = this.setFilterCurrencies.bind(this);
        if (Platform.OS != "web")
            BackHandler.addEventListener("hardwareBackPress", () => this.props.navigation.isFocused());

        this.state = {
            refreshing: false,
            filterCurrencies: false,
        };
    }

    onPress = async (id) => {
        this.props.selectCurrency(id);
        this.props.navigation.navigate(WalletNavigatorScreens.Wallet.routeName);
    };

    renderItem = ({ item }) => (
        <CurrencyCard
            priceHistory={true}
            onPress={() => this.onPress(item.getId())}
            currency={item}
            fiatCurrency={this.props.fiatCurrency}
        />
    );

    onRefresh = () => {
        WalletService.getInstance().syncBalance();
    };

    componentDidMount() {
        this.focusListener = this.props.navigation.addListener("focus", () => {
            this.forceUpdate();
        });
    }

    componentWillUnmount() {
        try {
            this.focusListener.remove();
        } catch (e) {}
    }

    componentDidUpdate(props) {
        //this.handleNavigation();
    }

    async handleNavigation() {
        if (this.props.redirection) {
            this.props.navigation.navigate(this.props.redirection);
            store.dispatch(setRedirection(null));
        }
    }

    setFilterCurrencies() {
        this.setState({ filterCurrencies: !this.state.filterCurrencies });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    showVersion={true}
                    type={HeaderType.Light}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(WalletNavigatorScreens.Profile.routeName, {
                                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                                params: {
                                    profileSection: ModuleControlService.getInstance().isModuleEnabled(
                                        Modules.poapsModule
                                    )
                                        ? ProfileSections.poapsProfile
                                        : ProfileSections.socialNetwork,
                                },
                            });
                        },
                        uri:
                            this.props.client &&
                            this.props.client?.profileImagePath &&
                            this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                    rightBtn={
                        ModuleControlService.getInstance().isServiceEnabled(Services.testnetService)
                            ? [
                                  {
                                      onPress: () => {
                                          this.props.navigation.navigate(
                                              WalletNavigatorScreens.WalletSettings.routeName
                                          );
                                      },
                                      icon: "settings",
                                      size: 22,
                                  },
                                  {
                                      onPress: () => {
                                          this.onRefresh();
                                      },
                                      icon: "refresh",
                                      size: 22,
                                  },
                              ]
                            : {
                                  onPress: () => {
                                      this.onRefresh();
                                  },
                                  icon: "refresh",
                                  size: 22,
                              }
                    }
                    {...this.props}
                />

                <Balance
                    testnet={Wallet.getInstance().isTestnet()}
                    amount={this.props.fiatCurrency.fiatFormat(this.props.totalBalance)}
                    title={t("total_balance")}
                    fiatCurrency={this.props.fiatCurrency}
                    syncing={this.props.syncing}
                />

                <WalletsButtons
                    syncing={this.props.syncing}
                    navigateTo={() =>
                        this.props.navigation.navigate(WalletNavigatorScreens.SelectCurrencyButtons.routeName)
                    }
                    navigation={this.props.navigation}
                ></WalletsButtons>

                <Container style={[styles.container, { opacity: this.props.syncing ? 0.5 : 1 }]}>
                    <FlatList
                        style={{ paddingTop: 5, paddingHorizontal: Platform.OS == "web" ? 10 : 20 }}
                        //data={this.props.currencies}
                        data={
                            this.state.filterCurrencies
                                ? Wallet.getInstance().getBalancedCurrencies()
                                : this.props.currencies
                        }
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.getId()}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        initialNumToRender={this.props.currencies.length}
                        showsHorizontalScrollIndicator={false}
                        maxToRenderPerBatch={10}
                        ListFooterComponent={
                            !this.props.syncing && (
                                <CardButton
                                    title={t("add_more_currencies")}
                                    style={{ marginBottom: 20 }}
                                    onPress={() => {
                                        this.setState({ filterCurrencies: false });
                                        this.props.navigation.navigate(
                                            WalletNavigatorScreens.SelectCurrencyEnable.routeName
                                        );
                                    }}
                                />
                            )
                        }
                        refreshControl={
                            <RefreshControl
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.props.syncing}
                            />
                        }
                        ListHeaderComponent={
                            <View>
                                <PressableBase onPress={this.setFilterCurrencies}>
                                    <Row
                                        style={{
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            alignSelf: "flex-end",
                                            backgroundColor: colors.shadow,
                                            paddingHorizontal: 10,
                                            paddingVertical: 4,
                                            borderRadius: 10,
                                            overflow: "hidden",
                                            marginBottom: 5,
                                        }}
                                    >
                                        <Icon
                                            style={{ paddingRight: 5 }}
                                            name={"currency-exchange"}
                                            size={14}
                                            color={colors.floatButtonText}
                                        />
                                        <RegularText fontSize={12}>
                                            {this.state.filterCurrencies ? t("see_more") : t("see_less")}
                                        </RegularText>
                                    </Row>
                                </PressableBase>
                                <ListHeaderButton {...this.props} />
                            </View>
                        }
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currencies: state.wallet.currencies,
        totalBalance: state.wallet.totalBalance,
        unconfirmedTotalBalance: state.wallet.unconfirmedTotalBalance,
        fiatCurrency: state.wallet.fiatCurrency,
        syncing: state.wallet.syncing,
        client: state.auth.client,
        redirection: state.global.redirection,
    };
};

const mapDispatchToProps = (dispatch) => ({
    selectCurrency: (id) => dispatch(selectCurrency(id)),
});

const WalletsScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletsScreen);

export default WalletsScreen;

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
    },
    container: {
        flex: 1,
        marginTop: -15,
        overflow: "hidden",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});
