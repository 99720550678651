import { Banner } from "@custom-types/BannerModel";

export const SET_BANNERS = "SET_BANNERS";
export const SET_INITIALIZING = "SET_INITIALIZING";

export const setInitializing = (initializing: boolean) => {
    return { type: SET_INITIALIZING, initializing };
};

export const setBanners = (banners: Array<Banner>) => {
    return { type: SET_BANNERS, banners };
};
