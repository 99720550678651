import Card from "@base/Card";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import LottieBase from "@components/animations/Lottie";
import AvatarBase from "@components/avatar/AvatarBase";
import ImageBase from "@components/image/ImageBase";
import {
    ActivityModel,
    MembershipActivityData
} from "@custom-types/ActivityModel";
import { Client } from "@custom-types/Client";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { getMembership } from "@screens/scanner/implementations/ExperiencesImplementation";
import { colors } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    activity: ActivityModel;
    client: Client;
}

interface State {
    activityData: MembershipActivityData;
}

const { t } = i18n;

export class _ActivityCardMembership extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.openMemership = this.openMemership.bind(this);
        this.state = {
            activityData: this.props?.activity?.data,
        };
    }

    openMemership() {
        getMembership(this.props.navigation, this.state.activityData?.membership?._id);
    }

    render() {
        return (
            <>
                {this.state.activityData && (
                    <Card
                        onPress={this.openMemership}
                        icon={
                            <View>
                                <AvatarBase
                                    size={45}
                                    uri={this.state.activityData?.membership?.merchant?.image?.thumbnail || ""}
                                    alias={this.state.activityData?.membership?.merchant?.name || ""}
                                ></AvatarBase>
                                <View style={styles.secondaryAvatar}>
                                    <AvatarBase
                                        size={20}
                                        uri={this.props.client?.profileImagePath?.thumbnail}
                                        alias={this.props.client?.alias || ""}
                                    ></AvatarBase>
                                </View>
                            </View>
                        }
                        left={
                            <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                <RegularText numberOfLines={2} fontSize={14} style={{ marginBottom: 2 }}>
                                    {t("congratulations")}{" "}
                                    <SemiBoldText fontSize={14}>
                                        {trimHelper(this.state.activityData?.membership?.merchant?.name, 10)}
                                    </SemiBoldText>{" "}
                                    {t("granted_you")}{" "}
                                    <SemiBoldText fontSize={14}>
                                        {trimHelper(this.state.activityData?.membership?.membershipOption?.name, 10)}
                                    </SemiBoldText>
                                    <RegularText fontSize={11}>
                                        <View style={{ width: 28, height: 15 }}>
                                            <View style={{ position: "absolute", top: -7, left: -1 }}>
                                                <LottieBase
                                                    height={32}
                                                    width={32}
                                                    loop={moment().diff(moment(this.props.activity?.createdAt), 'minutes') < 10}
                                                    source={require("../../../assets/animations/diamont.json")}
                                                />
                                            </View>
                                        </View>
                                        {"MEMBERSHIP"}
                                    </RegularText>
                                    <RegularText fontSize={11} color={colors.grey}>
                                        {"  ("}
                                        {moment(this.props.activity?.createdAt).fromNow()}
                                        {")"}
                                    </RegularText>
                                </RegularText>
                            </View>
                        }
                        right={
                            <View>
                                <ImageBase
                                    style={{ height: 50, width: 50, borderRadius: 10 }}
                                    uri={this.state.activityData?.membership?.membershipOption?.image?.thumbnail}
                                ></ImageBase>
                            </View>
                        }
                    >
                        {/* <Row style={{ width: "100%", justifyContent: "space-between" }}>
                            <Row style={{ flex: 1 }}>
                                <View style={{ marginLeft: 10, justifyContent: "center" }}>
                                    <RegularText fontSize={14} style={{ marginBottom: 2 }}>
                                        {t("asigned_by")}{" "}
                                        <SemiBoldText fontSize={14}>
                                            {trimHelper(this.state.activityData?.membership?.merchant?.name, 12)}
                                        </SemiBoldText>
                                    </RegularText>

                                    <Row style={{ alignContent: "center", alignItems: "center" }}>
                                        <AvatarBase
                                            size={15}
                                            uri={
                                                this.state.activityData?.membership?.membershipOption?.image
                                                    ?.thumbnail || ""
                                            }
                                            alias={this.state.activityData?.membership?.membershipOption?.name || ""}
                                        ></AvatarBase>
                                        <RegularText style={{ paddingLeft: 5 }} fontSize={14}>
                                            {trimHelper(
                                                this.state.activityData?.membership?.membershipOption?.name,
                                                20
                                            )}
                                        </RegularText>

                                        <View
                                            style={{
                                                marginLeft: 8,
                                                backgroundColor: getColorOpacity(colors.grey, 0.4),
                                                paddingHorizontal: 6,
                                                paddingVertical: 2,
                                                borderRadius: 10,
                                            }}
                                        >
                                            <SemiBoldText fontSize={8}>
                                                {"💎 "} {t("membership")}
                                            </SemiBoldText>
                                        </View>
                                    </Row>
                                </View>
                            </Row>

                            <ActivityCardDateComponet type={"flip"} date={this.props.activity?.createdAt} />
                        </Row> */}
                    </Card>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    secondaryAvatar: {
        position: "absolute",
        bottom: -2,
        right: -4,
        padding: 2,
        borderRadius: 20,
        backgroundColor: colors.gradientFrom,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ActivityCardMembership = connect(mapStateToProps, mapDispatchToProps)(_ActivityCardMembership);

export default ActivityCardMembership;
