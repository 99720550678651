import Container from "@base/Container";
import Header from "@components/header/Header";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import ExperienceService from "@core/services/ExperienceService";
import { Client } from "@custom-types/Client";
import { GateModel, RedeemableModel } from "@custom-types/ExpercienceModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { Route } from "@react-navigation/native";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import BoldText from "@base/BoldText";
import Card from "@base/Card";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import AvatarBase from "@components/avatar/AvatarBase";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { updateRedeemable } from "@store/actions/experiences.actions";
import { loading, ready, showPopup, showSnackbar } from "@store/actions/global";
import store from "@store/index";
import RedeemableWideCard from "./components/RedeemableWideCard";
import ActivityService from "@core/services/ActivityService";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<
        string,
        {
            redeemable: RedeemableModel;
            gate: GateModel;
        }
    >;
}

interface State {
    redeemable: RedeemableModel;
    gate: GateModel;
    showConfirm: boolean;
}

const { t } = i18n;

export class _ConfirmUseRedeemableScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.navigateToRedeemable = this.navigateToRedeemable.bind(this);
        this.state = {
            redeemable: this.props.route.params?.redeemable,
            gate: this.props.route.params?.gate,
            showConfirm: true,
        };
        this.onConfirm = this.onConfirm.bind(this);
    }

    async onConfirm() {
        store.dispatch(loading());

        const resp = await ExperienceService.getInstance().useRedeemable(
            this.state.redeemable?._id,
            this.state.gate._id
        );
        if (resp) {
            store.dispatch(updateRedeemable(resp));
            this.setState({ redeemable: resp });
        }

        this.setState({ showConfirm: false });
        store.dispatch(ready());
        store.dispatch(showPopup({ type: "SUCCESS" }));
        ActivityService.getInstance().getActivity(true);
        setTimeout(() => {
            this.navigateToRedeemable();
        }, 1000);
    }

    navigateToRedeemable() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Redeemable.routeName,
            params: { redeemable: this.state.redeemable, backTo: "HomeMain" },
        });
    }

    render() {
        return (
            <ScreenWrapper>
                <Header title={t("confirm")} type={HeaderType.Light} {...this.props} />

                <Container style={{ flex: 1, justifyContent: "space-between", paddingBottom: 20 }}>
                    <View>
                        <RedeemableWideCard redeemable={this.state.redeemable} navigation={this.props.navigation} />
                        <View style={{ paddingTop: 20 }}>
                            <View>
                                <BoldText> {t("owner")}</BoldText>
                                <Card style={{ flexDirection: "row" }}>
                                    <AvatarBase
                                        uri={
                                            this.state.redeemable?.owner?.profileImagePath?.thumbnail ||
                                            this.state.redeemable?.owner?.profileImagePath?.square ||
                                            ""
                                        }
                                        alias={this.state.redeemable?.owner?.alias}
                                        size={35}
                                    />

                                    <RegularText style={{ textAlignVertical: "center", paddingLeft: 10 }}>
                                        @{trimHelper(this.state.redeemable?.owner?.alias, 25)}
                                    </RegularText>
                                </Card>
                            </View>
                            <View style={{ paddingTop: 20 }}>
                                <BoldText>{t("use_in")}</BoldText>
                                <Card style={{ flexDirection: "row" }}>
                                    <AvatarBase
                                        uri={
                                            this.state.gate?.images[0]?.thumbnail ||
                                            this.state.gate.images[0]?.images?.cover ||
                                            ""
                                        }
                                        alias={this.state.gate?.name}
                                        size={35}
                                    />

                                    <View style={{ paddingLeft: 10 }}>
                                        <BoldText style={{ textAlignVertical: "center" }}>
                                            {trimHelper(this.state.gate?.name, 25)}
                                        </BoldText>
                                        <Row>
                                            <AvatarBase
                                                uri={
                                                    this.state.gate?.merchant?.image?.thumbnail ||
                                                    this.state.gate?.merchant?.image?.cover ||
                                                    ""
                                                }
                                                alias={this.state.gate?.merchant?.name}
                                                size={18}
                                            />
                                            <RegularText style={{ paddingLeft: 5 }} fontSize={12}>
                                                By {trimHelper(this.state.gate?.merchant?.name, 18)}
                                            </RegularText>
                                        </Row>
                                    </View>
                                </Card>
                            </View>
                        </View>
                    </View>
                    {this.state.showConfirm && (
                        <InlineButton title={t("confirm")} onPress={this.onConfirm}></InlineButton>
                    )}
                </Container>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ConfirmUseRedeemableScreen = connect(mapStateToProps, mapDispatchToProps)(_ConfirmUseRedeemableScreen);

export default ConfirmUseRedeemableScreen;
