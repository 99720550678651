const nunitoBold = require("./Brinnan-Black.otf")
const nunitoLight = require("./Brinnan-Light.otf")
const nunitoRegular = require("./Brinnan-Light.otf")
const nunitoSemiBold = require("./Brinnan-Bold.otf")

export const Fonts = {
    bold: nunitoBold,
    light: nunitoLight,
    regular: nunitoRegular,
    semiBold: nunitoSemiBold
}
