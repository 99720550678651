import React, { Component } from "react";
import { View, Animated, StyleSheet, DimensionValue, ViewStyle } from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { settings } from "@styles/globalStyles";

interface Props {
    containerStyles?: ViewStyle;
    width: DimensionValue;
    height: DimensionValue;
    radius?: number | string;
}

interface State {}

class Placeholder extends Component<Props, State> {
    private animatedValue: Animated.Value;

    constructor(props: Props) {
        super(props);

        this.animatedValue = new Animated.Value(0);
    }

    componentDidMount() {
        this.startAnimation();
    }

    startAnimation = () => {
        this.animatedValue.setValue(0);
        Animated.loop(
            Animated.timing(this.animatedValue, {
                toValue: 1,
                duration: 1000,
                useNativeDriver: true,
            })
        ).start();
    };

    render() {
        const translateX = this.animatedValue.interpolate({
            inputRange: [0, 1],
            outputRange: [-200, 200],
        });

        return (
            <View style={[styles.container, this.props.containerStyles]}>
                <View
                    style={[
                        styles.placeholderContainer,
                        {
                            width: this.props.width,
                            height: this.props.height,
                            borderRadius: this.props.radius ? this.props.radius : settings.cardRadius,
                        },
                    ]}
                >
                    <View style={styles.placeholder} />
                    <Animated.View
                        style={[
                            styles.shimmer,
                            {
                                transform: [{ translateX }],
                            },
                        ]}
                    >
                        <LinearGradient
                            colors={["rgba(180,180,180,0)", "rgba(180,180,180,0.3)", "rgba(180,180,180,0)"]}
                            start={{ x: 0, y: 0 }}
                            end={{ x: 1, y: 0 }}
                            style={styles.gradient}
                        />
                    </Animated.View>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        padding: 10,
    },
    placeholderContainer: {
        backgroundColor: "#696969",
        overflow: "hidden",
    },
    placeholder: {
        width: "100%",
        height: "100%",
        backgroundColor: "#696969",
    },
    shimmer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "transparent",
    },
    gradient: {
        flex: 1,
    },
});

export default Placeholder;
