import { Banner } from "@custom-types/BannerModel";
import { WalletConnectDappModel, DappModel } from "@custom-types/DappModel";
import { SET_BANNERS } from "@store/actions/banners.actions";
import { SET_ALL_DAPPS, SET_WC_DAPPS, SET_XO_DAPPS } from "@store/actions/dapps.actions";
import { RELOAD } from "@store/actions/global";

interface state {
    XoDapps: { docs: Array<DappModel>; paginator: { page: number; totalDocs: number; totalPages: number } };
    WalletConnectDapps: { docs: Array<DappModel>; paginator: { page: number; totalDocs: number } };
    allDapps: Array<DappModel>;
}

const initialState: state = {
    allDapps: [],
    XoDapps: { docs: [], paginator: { page: 0, totalDocs: 0, totalPages: 1 } },
    WalletConnectDapps: { docs: [], paginator: { page: 0, totalDocs: 0 } },
};

const dappsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_DAPPS:
            return {
                ...state,
                allDapps: action.dapps,
            };

        case SET_XO_DAPPS:
            return {
                ...state,
                XoDapps: {
                    docs: action.dapps,
                    paginator: action.paginator,
                },
            };

        case SET_WC_DAPPS:
            return {
                ...state,
                WalletConnectDapps: {
                    docs: action.dapps,
                    paginator: action.paginator,
                },
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default dappsReducer;
