import React, { Component } from "react";
import { View, StyleSheet, ViewStyle } from "react-native";
import i18n from "@i18n/i18n";
import { Client } from "@custom-types/Client";
import BoldText from "@base/BoldText";
import { IOAuthHistoryStorage } from "@utils/storage/storages/OAuthHistoryStorage";
import SeedCard from "@components/cards/SeedCard";
import store from "@store/index";
import { loading, ready, reset, showPopup } from "@store/actions/global";
import { OAuthService } from "@core/services/oauth/OAuthService";
import { OAuthHistoryService } from "@core/services/oauth/OAuthHistoryService";
import { colors } from "@styles/globalStyles";
import OldSeedHistoryList from "./OldSeedHistoryList";
import Wallet from "@core/wallet/Wallet";

interface Props {
    navigation: any;
    contentContainerStyle?: ViewStyle;
    listTitle?: string;
    listHeader?: any;
    redirectToCreateAccount?: () => void;
}

interface State {
    oAuthHistory: Array<IOAuthHistoryStorage>;
    selected: string;
    stateLoaded: boolean;
}

const { t } = i18n;

export default class SeedHistoryList extends Component<Props, State> {
    oAuthService: OAuthService;

    constructor(props: Props) {
        super(props);
        this.oAuthService = OAuthService.getInstance();
        this.loadState = this.loadState.bind(this);
        this.importSeed = this.importSeed.bind(this);
        this.recoverSeed = this.recoverSeed.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.selectItem = this.selectItem.bind(this);
        this.deselectItem = this.deselectItem.bind(this);
        this.state = {
            oAuthHistory: [],
            selected: "",
            stateLoaded: false,
        };
    }

    async componentDidMount() {
        await this.loadState();
    }

    async loadState() {
        let history = (await this.oAuthService.getHistory()) || [];
        if (history) {
            const firstOnList = history.filter((h) => h.mnemonic == Wallet.getInstance().getMnemonic().join(" "));
            const otherSeeds = history.filter((h) => h.mnemonic !== Wallet.getInstance().getMnemonic().join(" "));
            history = [...firstOnList, ...otherSeeds];
        }
        this.setState({ oAuthHistory: history, stateLoaded: true });

        if (history?.length == 0) {
            this.props.redirectToCreateAccount ? this.props.redirectToCreateAccount() : null;
        }
    }

    async importSeed(mnemonic: string, client: Client) {
        store.dispatch(loading());
        const words = mnemonic.match(/\S+/g) || [];
        if (words?.length == 12) {
            store.dispatch(loading());
            await this.oAuthService.restoreSeed(mnemonic, client);

            this.props.navigation.reset({
                index: 0,
                routes: [{ name: "Initializer" }],
            });
        }
        store.dispatch(ready());
    }

    async recoverSeed(client: Client) {
        const mnemonic = await this.oAuthService.recoverSeed(client);
        if (mnemonic) {
            return await this.importSeed(mnemonic, client);
        } else {
            if (!this.oAuthService.getClient()) {
                this.props.navigation.reset({
                    index: 0,
                    routes: [{ name: "Initializer" }],
                });
            }
        }
    }

    async removeItem(mnemonic: string) {
        store.dispatch(loading());
        await OAuthHistoryService.removeClientHistory(mnemonic);
        await this.loadState();
        store.dispatch(ready());
        store.dispatch(showPopup({ type: "SUCCESS" }));
    }

    selectItem(s: string) {
        this.setState({ selected: s });
        setTimeout(() => {
            this.setState({ selected: "" });
        }, 10000);
    }

    deselectItem() {
        this.setState({ selected: "" });
    }

    render() {
        return (
            <View style={this.props.contentContainerStyle}>
                {this.props.listHeader && this.state.oAuthHistory?.length > 0 && this.props.listHeader}

                {this.props.listTitle?.length && this.state.oAuthHistory?.length > 0 && (
                    <View style={{ paddingBottom: 10 }}>
                        <BoldText>{this.props.listTitle}</BoldText>
                    </View>
                )}
                {this.state.oAuthHistory?.length > 0 && (
                    <View style={{ flex: 1 }}>
                        {this.state.oAuthHistory.map((h) => {
                            return (
                                <SeedCard
                                    key={h?.clientId || ""}
                                    data={h}
                                    isActive={h.mnemonic == Wallet.getInstance().getMnemonic().join(" ")}
                                    selected={this.state.selected}
                                    recoverSeed={this.recoverSeed}
                                    importSeed={this.importSeed}
                                    removeItem={this.removeItem}
                                    selectItem={this.selectItem}
                                    deselectItem={this.deselectItem}
                                ></SeedCard>
                            );
                        })}
                    </View>
                )}
                {this.state.stateLoaded && <OldSeedHistoryList navigation={this.props.navigation}></OldSeedHistoryList>}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    active: {
        backgroundColor: colors.secondary,
        borderRadius: 5,
        justifyContent: "center",
        alignItems: "center",
        alignContent: "center",
        paddingHorizontal: 5,
    },
});
