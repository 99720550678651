import NavigationInstantiator from "./helpers/NavigationInstantiator";
import { ProfileNavigator } from "./ProfileNavigator";
import { NotificationsNavigator } from "./NotificationsNavigator";
import HomeScreen from "@screens/home/HomeScreen";
import { ExperienceNavigator } from "./ExperienceNavigator";
import { POAPsNavigator } from "./POAPsNavigator";
import DiscoverExplorerScreen from "@screens/social/DiscoverExplorerScreen";
import XoConnectScreen from "@screens/xoconnect/XoConnectScreen";
import { BlogNavigator } from "./BlogNavigator";
import { ActivityNavigator } from "./ActivityNavigator";
import { DappsNavigator } from "./DappsNavigator";

const HomeNavigatorScreens = {
    HomeMain: {
        routeName: "HomeMain",
        title: "Home",
        component: HomeScreen,
    },
    Experience: {
        routeName: "Experience",
        title: "Experience",
        component: ExperienceNavigator,
    },
    Profile: {
        routeName: "Profile",
        title: "Profile",
        component: ProfileNavigator,
    },
    DiscoverExplorer: {
        routeName: "DiscoverExplorer",
        title: "DiscoverExplorer",
        component: DiscoverExplorerScreen,
    },
    POAPs: {
        routeName: "POAPs",
        title: "POAPs",
        component: POAPsNavigator,
    },
    Notifications: {
        routeName: "Notifications",
        title: "Notifications",
        component: NotificationsNavigator,
    },
    Blogs: {
        routeName: "Blogs",
        title: "Blogs",
        component: BlogNavigator,
    },
    XoConnectScreen: {
        routeName: "XoConnectScreen",
        title: "XoConnectScreen",
        component: XoConnectScreen,
    },
    Activity: {
        routeName: "Activity",
        title: "Activity",
        component: ActivityNavigator,
    },
    Dapps: {
        routeName: "Dapps",
        title: "Dapps",
        component: DappsNavigator,
    },
};

const HomeNavigator = NavigationInstantiator(HomeNavigatorScreens);

export { HomeNavigator, HomeNavigatorScreens };
