import { Banner } from "@custom-types/BannerModel";
import { SET_BANNERS, SET_INITIALIZING } from "@store/actions/banners.actions";
import { RELOAD } from "@store/actions/global";

interface state {
    banners: Array<Banner>;
    initializing: boolean;
}

const initialState: state = {
    banners: [],
    initializing: true,
};

const bannersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_INITIALIZING:
            return {
                ...state,
                initializing: action.initializing,
            };
        case SET_BANNERS:
            return {
                ...state,
                banners: [...action.banners],
            };

        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default bannersReducer;
