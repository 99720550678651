import { Header } from "@components/header/Header";
import WebViewBase from "@components/webview/WebViewBase";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import { IWalletConnectSession, IWalletConnectStore } from "@core/services/WalletConnectDataService";
import { DappModel } from "@custom-types/DappModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { Route } from "@react-navigation/native";
import { isWalletConnect } from "@screens/scanner/implementations/WalletConnectImplementation";
import { showPopup } from "@store/actions/global";
import store from "@store/index";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import * as Clipboard from "expo-clipboard";
import React, { Component } from "react";

import { Platform, StyleSheet, View } from "react-native";
import { WebView } from "react-native-webview";
import { connect } from "react-redux";

interface Props {
    navigation: NavigationType;
    route: Route<string, { dapp: DappModel }>;
}

interface State {
    dapp: DappModel;
}

const { t } = i18n;

export class _DappsWebViewScreen extends Component<Props, State> {
    clipboardListener: any;

    constructor(props: Props) {
        super(props);

        this.state = {
            dapp: this.props.route.params?.dapp || null,
        };
    }
    async componentDidMount() {
        this.evaluateClipboard();
    }

    componentWillUnmount() {
        Clipboard.removeClipboardListener(this.clipboardListener);
    }

    async evaluateClipboard() {
        this.clipboardListener = Clipboard.addClipboardListener(({ contentTypes }: any) => {
            if (contentTypes.includes(Clipboard.ContentType.PLAIN_TEXT)) {
                Clipboard.getStringAsync().then((content) => {
                    isWalletConnect(content);
                });
            }
        });
    }

    onMessage = (event) => {
        const message = event.nativeEvent.data;
     
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.dapp?.name, 18) || ""}
                    type={HeaderType.Light}
                    rigthAvatar={{
                        onPress: () => null,
                        uri: this.state.dapp?.image?.thumbnail || "",
                        alias: this.state.dapp?.name || "",
                        size: 35,
                    }}
                    {...this.props}
                />
                <WebViewBase
                    //     injectedJavaScript={`
                    //     window.ReactNativeWebView.postMessage('INIT');
                    //     const checkModal = () => {
                    //         const modal = document.querySelector('.walletconnect-modal, wcm-qrcode');

                    //       if (modal) {
                    //         window.ReactNativeWebView.postMessage('walletconnect_modal_opened');
                    //       } else {
                    //         window.ReactNativeWebView.postMessage('NOT');
                    //       }
                    //     };

                    //     checkModal();
                    //     setInterval(checkModal, 1000); // Verifica cada segundo
                    //   `}
                    source={this.state.dapp.url}
                    onMessage={this.onMessage}
                ></WebViewBase>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
     
    };
};

const mapDispatchToProps = (dispatch) => ({});
const DappsWebViewScreen = connect(mapStateToProps, mapDispatchToProps)(_DappsWebViewScreen);
export default DappsWebViewScreen;

const styles = StyleSheet.create({});
