import React, { Component } from "react";
import { StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import { NavigationType } from "@custom-types/NavigationType";
import Container from "@base/Container";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import SearchBase from "@components/search/SearchBase";
import Header from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import FlatListBase from "@base/FlatListBase";
import { ExperienceModel, ExperienceSearchParams } from "@custom-types/ExpercienceModel";
import ExperienceService from "@core/services/ExperienceService";
import { Client } from "@custom-types/Client";
import { ExperiencesNavigatorScreens } from "@navigation/ExperiencesNavigator";
import { ProfileNavigatorScreens } from "@navigation/ProfileNavigator";
import { ModuleControlService, Modules } from "@core/services/ModuleControlService";
import { ProfileSections } from "@custom-types/ProfileType";
import BotCard from "@base/BotCard";
import { colors, settings } from "@styles/globalStyles";
import ExperienceSearchCard from "./components/ExperienceSearchCard";
import debounce from "lodash.debounce";
interface Props {
    navigation: NavigationType;
    data?: Array<ExperienceModel>;
    client: Client;
}

interface State {
    onSearch: boolean;
    searching: boolean;
    searchExperience: Array<ExperienceModel>;
    filters: ExperienceSearchParams;
}

const { t } = i18n;

export class _ExperiencesMainScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            onSearch: false,
            searching: false,
            searchExperience: [],
            filters: {
                name: "",
            },
        };

        this.applyFilter = debounce(this.applyFilter, 300);
    }

    renderItem = ({ item }) => {
        return <ExperienceSearchCard experience={item} navigation={this.props.navigation} />;
    };

    searchByName = async (search: string) => {
        this.setState({ filters: { ...this.state.filters, name: search }, searching: true });
        this.applyFilter();
    };

    applyFilter = async () => {
        const resp = await ExperienceService.getInstance().searchExperiences(this.state.filters);
        this.setState({ searchExperience: resp.docs, onSearch: this.state.filters.name.length > 0, searching: false });
    };

    loadMore = async () => {
        await ExperienceService.getInstance().getExperiences(true);
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    title={t("experiences")}
                    leftAvatar={{
                        onPress: () => {
                            this.props.navigation.navigate(ExperiencesNavigatorScreens.Profile.routeName, {
                                screen: ProfileNavigatorScreens.ProfileMain.routeName,
                                params: {
                                    profileSection: ModuleControlService.getInstance().isModuleEnabled(
                                        Modules.poapsModule,
                                    )
                                        ? ProfileSections.poapsProfile
                                        : ProfileSections.socialNetwork,
                                },
                            });
                        },
                        uri:
                            this.props.client &&
                                this.props.client?.profileImagePath &&
                                this.props.client?.profileImagePath !== null
                                ? this.props.client?.profileImagePath.thumbnail
                                : "",
                        alias: this.props.client && this.props.client?.alias ? this.props.client?.alias : "",
                        size: 35,
                    }}
                    rightBtn={[
                        {
                            onPress: () => {
                                this.props.navigation.navigate("Experience");
                            },
                            icon: "ticket",
                            size: 22,
                        },
                    ]}
                    {...this.props}
                />
                <Container style={{ flex: 1 }}>
                    <View style={{ marginBottom: 20 }}>
                        <SearchBase
                            value={this.state.filters.name}
                            searching={this.state.searching}
                            onSearch={(value) => this.searchByName(value)}
                        />
                    </View>
                    <FlatListBase
                        data={this.state.onSearch ? this.state.searchExperience : this.props.data}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item._id}
                        contentContainerStyle={{ paddingBottom: 20 }}
                        showsHorizontalScrollIndicator={false}
                        ListEmptyComponent={<BotCard message={t("empty_list")} />}
                        onEndReached={this.loadMore}
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.experiences.experiences.docs,
        client: state.auth.client,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperiencesMainScreen = connect(mapStateToProps, mapDispatchToProps)(_ExperiencesMainScreen);

export default ExperiencesMainScreen;

const styles = StyleSheet.create({
    filterContainer: {
        width: "100%",
        paddingVertical: 10,
    },
    dateCard: {
        flex: 1,
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 15,
        paddingVertical: 14,
        marginTop: 5,
    },
    tag: {
        justifyContent: "center",
        alignItems: "center",
        width: "auto",
        backgroundColor: colors.complementary,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 10,
        paddingVertical: 5,
        marginTop: 10,
    },
});
