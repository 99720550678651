import ActivityService from "@core/services/ActivityService";
import ExperienceService from "@core/services/ExperienceService";
import { ExperienceModuleType, ModuleControlService } from "@core/services/ModuleControlService";
import { RedeemableModel } from "@custom-types/ExpercienceModel";
import i18n from "@i18n/i18n";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { TabsNavigatorScreens } from "@navigation/TabsNavigator";
import { hideScanner, loading, ready, showPopup, showPopupMessage } from "@store/actions/global";
import store from "@store/index";

const { t } = i18n;

export const isExperience = (navigation, qrCode) => {
    const extractQuery = qrCode?.split("?");
    const actionData = extractQuery[1]?.split("=") || "";

    switch (actionData[0]) {
        case "redeemable":
            return getRedeemable(navigation, actionData[1]);
        case "gate":
            return getGate(navigation, actionData[1]);
        case "merchant":
            return console.warn("Get Merchant");
        case "membership":
            return getMembership(navigation, actionData[1]);
        case "asset":
            return getAsset(navigation, actionData[1]);

        case "membership.option":
            return claimMembership(navigation, actionData[1]);
        case "asset.option":
            return claimAsset(navigation, actionData[1]);
        default:
            return;
    }
};

export const getGate = async (navigation, id?: string) => {
    store.dispatch(loading());
    const resp = await ExperienceService.getInstance().getGate(id);

    if (
        ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) &&
        resp?.redeemables?.length > 0
    ) {
        ExperienceService.getInstance().setMerchant(resp?.gate?.merchant);
        await ExperienceService.getInstance().getRedeemables();
        ExperienceService.getInstance().getMemberships();
        ExperienceService.getInstance().getAssets();
    }
    store.dispatch(ready());

    if (resp?.redeemables?.length == 0) {
        store.dispatch(hideScanner());
        store.dispatch(
            showPopup({
                type: "ERROR",
                message: t("redeemables_error_message"),
            })
        );
    }

    if (resp?.redeemables?.length == 1) {
        store.dispatch(loading());
        const redeemable = await ExperienceService.getInstance().getRedeemable(resp?.redeemables[0]?._id);
        redirectToConfirm(navigation, redeemable, resp?.gate);
        store.dispatch(ready());
    }

    if (resp?.redeemables?.length > 1) {
        //@ts-ignore
        const selectedRedeemable = store.getState().experiences?.selectedRedeemable;

        const isSelectedRedeemable = resp?.redeemables.filter((r) => {
            if (r._id == selectedRedeemable?._id) {
                return selectedRedeemable;
            }
        });

        if (isSelectedRedeemable?.length === 1) {
            redirectToConfirm(navigation, selectedRedeemable, resp?.gate);
        } else {
            store.dispatch(hideScanner());
            navigation.navigate("Experience", {
                screen: "SelectToUse",
                params: { redeemables: resp?.redeemables, gate: resp?.gate },
            });
        }
    }
};

export const redirectToConfirm = (navigation, redeemable: RedeemableModel, gate) => {
    store.dispatch(hideScanner());
    navigation.navigate("Experience", {
        screen: ExperienceNavigatorScreens.ConfirmUseRedeemable.routeName,
        params: { redeemable: redeemable, gate: gate },
    });
};

export const getRedeemable = async (navigation, id) => {
    {
        store.dispatch(loading());
        store.dispatch(hideScanner());
        const redeemable = await ExperienceService.getInstance().getRedeemable(id);
        if (redeemable) {
            navigation.navigate("Home");
            navigation.navigate("Experience", {
                screen: ExperienceNavigatorScreens.Redeemable.routeName,
                params: { redeemable: redeemable },
            });
        }
        store.dispatch(ready());
    }
};

export const getMembership = async (navigation, id) => {
    {
        store.dispatch(loading());
        store.dispatch(hideScanner());
        const membership = await ExperienceService.getInstance().getMembership(id);
        if (membership) {
            navigation.navigate("Home");
            navigation.navigate("Experience", {
                screen: ExperienceNavigatorScreens.Membership.routeName,
                params: { membership: membership },
            });
        }
        store.dispatch(ready());
    }
};

export const getAsset = async (navigation, id) => {
    store.dispatch(loading());
    store.dispatch(hideScanner());
    const asset = await ExperienceService.getInstance().getAsset(id);
    if (asset) {
        navigation.navigate("Home");
        navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.Asset.routeName,
            params: { asset: asset },
        });
    }
    store.dispatch(ready());
};

export const claimMembership = async (navigation, id) => {
    store.dispatch(loading());
    store.dispatch(hideScanner());
    const m = await ExperienceService.getInstance().claimMembership(id);
    if (ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) && m) {
        await ExperienceService.getInstance().getMerchants();
        ExperienceService.getInstance().setMerchant(m?.merchant);
        ExperienceService.getInstance().getExperiences();
    }
    if (m) {
        ExperienceService.getInstance().getMemberships();
        ExperienceService.getInstance().getRedeemables();
        ExperienceService.getInstance().getAssets();
        ActivityService.getInstance().getActivity();

        navigation.navigate(TabsNavigatorScreens.Home.routeName);
        store.dispatch(
            showPopup({
                type: "SUCCESS",
                message: `${t("congratulations")} ${t("info_receive")} ${m.membershipOption?.name}`,
            })
        );
    }
    store.dispatch(ready());
};

export const claimAsset = async (navigation, id) => {
    store.dispatch(loading());
    store.dispatch(hideScanner());
    const a = await ExperienceService.getInstance().claimAsset(id);
    if (ModuleControlService.getInstance().getActiveExperienceModuleType(ExperienceModuleType.exclusive) && a) {
        await ExperienceService.getInstance().getMerchants();
        ExperienceService.getInstance().setMerchant(a?.merchant);
    }
    if (a) {
        ExperienceService.getInstance().getRedeemables();
        ExperienceService.getInstance().getMemberships();
        ExperienceService.getInstance().getAssets();
        ActivityService.getInstance().getActivity();
        navigation.navigate(TabsNavigatorScreens.Home.routeName);

        store.dispatch(
            showPopup({
                type: "SUCCESS",
                message: `${t("congratulations")} ${t("info_receive")} ${a.assetOption?.name}`,
            })
        );
    }
    store.dispatch(ready());
};
