import DappsExplorerScreen from "@screens/dapps/DappsExplorerScreen"
import NavigationInstantiator from "./helpers/NavigationInstantiator"
import DappsWebViewScreen from "@screens/dapps/DappsWebViewScreen"


const DappsNavigatorScreens = {
    DaapsExplorer: {
        routeName: "DaapsExplorer",
        title: "Dapps",
        component: DappsExplorerScreen,
    },

    DaapsWebView: {
        routeName: "DaapsWebView",
        title: "Dapp Explorer",
        component: DappsWebViewScreen,
    },

    // DaapsWebViewExplore: {
    //     routeName: "DaapsWebViewExplore",
    //     title: "Dapp Explore",
    //     component: DappsWebViewExploreScreen,
    // },

}
const DappsNavigator = NavigationInstantiator(DappsNavigatorScreens)

export { DappsNavigator, DappsNavigatorScreens }
