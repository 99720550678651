import BoldText from "@base/BoldText";
import PressableBase from "@base/PressableBase";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import AvatarBase from "@components/avatar/AvatarBase";
import Icon from "@components/icons";
import Currency from "@core/currencies/Currency";
import DappsService from "@core/services/DappsService";
import Wallet from "@core/wallet/Wallet";
import { WalletConnectDappModel, DappModel } from "@custom-types/DappModel";
import i18n from "@i18n/i18n";
import { colors, settings } from "@styles/globalStyles";
import { getColorOpacity } from "@utils/helpers/global/global";
import { LinearGradient } from "expo-linear-gradient";
import React, { Component } from "react";
import { Image, StyleSheet, View } from "react-native";

interface Props {
    dapp: DappModel;
    onPress?: (dapp) => void;
    onLongPress?: () => void;
}

const { t } = i18n;

interface State {
    dapp: DappModel;
}

export default class DappCard extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onLongPress = this.onLongPress.bind(this);
        this.state = {
            dapp: this.props.dapp || null,
        };
    }

    async onLongPress() {
        if (this.state.dapp.type !== "xo") return;
        await DappsService.getInstance().favoriteDapp(this.state.dapp);
    }

    render() {
        return (
            <>
                {this.state.dapp && (
                    <PressableBase
                        onPress={() => this.props.onPress(this.state.dapp)}
                        onLongPress={this.onLongPress}
                        style={styles.wrapper}
                    >
                        <LinearGradient
                            style={{
                                position: "absolute",
                                width: "120%",
                                height: "120%",
                            }}
                            colors={[getColorOpacity(colors.shadow, 0.25), colors.tertiary]}
                        ></LinearGradient>
                        <Image
                            style={{
                                position: "absolute",
                                opacity: 0.85,
                                width: "200%",
                                height: "200%",
                                resizeMode: "cover",
                            }}
                            blurRadius={50}
                            source={{ uri: this.state.dapp.image?.thumbnail }}
                        />
                        <View style={{ justifyContent: "space-between", flex: 1, width: "100%" }}>
                            <Row style={{ justifyContent: "space-between", alignItems: "center", paddingBottom: 8 }}>
                                {/* <PressableBase //onPress={this.setFavorite}
                                >
                                    {this.state.dapp?.walletConnectSupport ? (
                                        <Icon color={colors.yellow} size={18} name="star-fill" />
                                    ) : (
                                        <Icon color={colors.grey} size={18} name="star" />
                                    )}
                                </PressableBase> */}
                                <Row style={{ justifyContent: "flex-end" }}>
                                    {this.state.dapp?.digitalCurrencies?.map((c: Currency) => {
                                        return (
                                            <CurrencyIcon
                                                key={c.getId()}
                                                size={18}
                                                styles={{ marginLeft: 3 }}
                                                currency={c}
                                            />
                                        );
                                    })}
                                </Row>
                            </Row>
                            <View>
                                <AvatarBase
                                    size={100}
                                    alias={this.state.dapp?.name}
                                    uri={this.state.dapp?.image?.thumbnail}
                                ></AvatarBase>
                                <BoldText style={{ paddingTop: 5 }} align="center" numberOfLines={1}>
                                    {this.state.dapp?.name}
                                </BoldText>
                            </View>
                        </View>
                        <View style={{ height: 30, width: "100%", justifyContent: "flex-end", paddingHorizontal: 4 }}>
                            {this.state?.dapp?.type == "xo" && (
                                <Row
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        backgroundColor: getColorOpacity(colors.secondary, 0.5),
                                        alignSelf: "flex-start",
                                        paddingLeft: 2,
                                        paddingRight: 5,
                                        paddingVertical: 1,
                                        borderRadius: 10,
                                    }}
                                >
                                    <Icon name={"uniE924"} color={colors.text} />
                                    <RegularText fontSize={12}> {t("verified")}</RegularText>
                                </Row>
                            )}

                            {this.state?.dapp?.type == "walletConnect" && (
                                <Row
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        backgroundColor: getColorOpacity(colors.grey, 1),
                                        alignSelf: "flex-start",
                                        height: 18,
                                        width: 18,
                                        borderRadius: 9,
                                    }}
                                >
                                    <Icon size={12} name={"WalletConnect-icon"} color={colors.text} />
                                </Row>
                            )}
                        </View>
                    </PressableBase>
                )}
            </>
        );
    }
}

const styles = StyleSheet.create({
    wrapper: {
        flex: 1,
        marginHorizontal: 5,
        marginVertical: 5,
        width: 160,
        height: 200,
        borderRadius: settings.cardRadius,
        paddingHorizontal: 6,
        paddingVertical: 6,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
});
