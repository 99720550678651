import Container from "@base/Container";
import Row from "@base/Row";
import { Header } from "@components/header/Header";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import { colors, settings } from "@styles/globalStyles";
import React, { Component } from "react";
import { RefreshControl, StyleSheet, View } from "react-native";
import Card from "@base/Card";
import CircleButton from "@base/CircleButton";
import Label from "@base/Label";
import RegularText from "@base/RegularText";
import SemiBoldText from "@base/SemiBold";
import CurrencyIcon from "@components/accessories/CurrencyIcon";
import CurrencyBalance from "@components/wallet/CurrencyBalance";
import { ButtonActionsType } from "@components/wallet/WalletsButtons";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { ModuleControlService, Services } from "@core/services/ModuleControlService";
import Wallet from "@core/wallet/Wallet";
import { SimpleLineIcons } from "@expo/vector-icons";
import i18n from "@i18n/i18n";
import { ExchangeNavigatorScreens } from "@navigation/ExchangeNavigator";
import { SendNavigatorScreens } from "@navigation/SendNavigator";
import { WalletNavigatorScreens } from "@navigation/WalletNavigator";
import { loading, ready, showModalBottom } from "@store/actions/global";
import {
    selectCurrency,
    selectTransaction,
    cleanOperation,
    selectExchangeFrom,
    setButtonActionsType,
} from "@store/actions/wallet";
import store from "@store/index";
import { FlatList } from "react-native";
import { connect } from "react-redux";
import TransactionDetailComponent from "./transaction/TransactionDetailComponent";

interface Props {
    navigation: NavigationType;
    currencies: Array<Currency>;
    selectedCurrency: string;
    fiatCurrency: FiatCurrency;
    getTransactions: (currency: Currency, txid?: string) => void;
    syncing: boolean;
    route: any;
}

interface State {
    transactions: Array<any>;
    syncing: boolean;
    showBotCard: boolean;
}

const { t } = i18n;

export class _WalletScreen extends Component<Props, State> {
    protected currency: Currency;

    constructor(props: Props) {
        super(props);
        this.currency = Wallet.getInstance().findCurrencyById(this.props.selectedCurrency);
        this.openTransaction = this.openTransaction.bind(this);
        this.onPressDetail = this.onPressDetail.bind(this);
        this.onPressSend = this.onPressSend.bind(this);
        this.onPressService = this.onPressService.bind(this);
        this.onPressExchange = this.onPressExchange.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onDisplay = this.onDisplay.bind(this);
        this.renderEmptyList = this.renderEmptyList.bind(this);
        this.renderListHeader = this.renderListHeader.bind(this);
        this.state = {
            transactions: this.currency.getTransactions(),
            syncing: false,
            showBotCard: true,
        };
    }

    async onDisplay() {
        this.setState({ syncing: true });
        await this.currency.syncTransactions();
        await this.currency.syncBalance();
        this.setState({ transactions: this.currency.getTransactions(), syncing: false }, () => this.forceUpdate());
    }

    async componentDidMount() {
        this.props.navigation.addListener("focus", this.onDisplay);
        setTimeout(() => {
            this.setState({ showBotCard: false });
        }, 10000);
    }

    onPressDetail(index) {
        store.dispatch(selectTransaction(index));
        this.props.navigation.navigate(WalletNavigatorScreens.TransactionDetail.routeName);
    }

    async openTransaction(item, index) {
        store.dispatch(loading());
        const hash = item?.id || "";
        let transaction = await this.currency.getTransactionByHash(hash);
        store.dispatch(ready());
        if (this.currency && (transaction?.from || transaction?.id || transaction?.to)) {
            store.dispatch(
                showModalBottom({
                    modalContent: (
                        <TransactionDetailComponent
                         
                            currency={this.currency}
                            transaction={transaction}
                            {...this.props}
                        />
                    ),
                }),
            );
            return;
        }

        transaction = this.currency.getTransaction(index);
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <TransactionDetailComponent currency={this.currency} transaction={transaction} {...this.props} />
                ),
            }),
        );
    }

    onPressSend() {
        store.dispatch(cleanOperation());
        store.dispatch(selectCurrency(this.currency.getId()));
        this.props.navigation.navigate(WalletNavigatorScreens.Send.routeName, {
            screen: SendNavigatorScreens.SendMain.routeName,
            params: { selectedCurrency: this.currency.getId(), showFindClient: true },
        });
    }

    onPressService(type: ButtonActionsType) {
        store.dispatch(cleanOperation());
        store.dispatch(selectCurrency(this.currency.getId()));
        store.dispatch(setButtonActionsType(type));
        this.props.navigation.navigate(WalletNavigatorScreens.Buy.routeName);
    }

    renderItem = ({ item, index }) => {
        return (
            <Card
                style={{ borderLeftColor: item.confirmations >= 6 ? colors.green : colors.yellow, borderLeftWidth: 10 }}
                key={item.id}
                onPress={() => this.openTransaction(item, index)}
                icon={
                    <View style={styles.icon}>
                        <SimpleLineIcons name="lock" size={16} align="center" color={colors.text} />

                        {item.confirmations < 6 && item.confirmations !== null && (
                            <SemiBoldText fontSize={10}> {item.confirmations}/6 </SemiBoldText>
                        )}
                        {item.confirmations >= 6 && <SemiBoldText fontSize={10}> +6 </SemiBoldText>}
                    </View>
                }
                left={
                    <View>
                        <SemiBoldText> {item.type == 1 ? t("sent") : t("received")} </SemiBoldText>
                        <RegularText fontSize={10}>
                            {" "}
                            {this.currency.getImplementation().parseDate(item.time)}{" "}
                        </RegularText>
                    </View>
                }
                right={
                    <View>
                        {item.amount > 0 && (
                            <RegularText> {this.currency.format(this.currency.toDecimals(item.amount))} </RegularText>
                        )}
                        {item.amount == 0 && <Label text="Contract excecution" fontSize={10} />}
                    </View>
                }
            />
        );
    };

    onRefresh = async () => {
        await this.onDisplay();
    };

    onPressExchange = () => {
        store.dispatch(selectExchangeFrom({ currency: this.currency }));
        this.props.navigation.navigate(ExchangeNavigatorScreens.Exchange.routeName);
    };

    renderEmptyList = () => {
        return (
            <View>
                {!this.props.syncing && !this.state.syncing && (
                    <Card>
                        <RegularText align="center">
                            {t("activity_transactions", { currency: this.currency.getPName() })}
                        </RegularText>
                    </Card>
                )}
            </View>
        );
    };

    renderListHeader = () => {
        return (
            <View>
                <CurrencyBalance
                    amount={this.currency.getFormatTotalBalance()}
                    balance={this.currency.getBalance()}
                    title={this.currency.getPName() + " " + t("balance")}
                    currency={this.currency}
                    fiatCurrency={this.props.fiatCurrency}
                />
                <Row style={styles.buttons}>
                    {this.currency.isExchangeAvailable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.exchangeService) && (
                            <CircleButton title={t("exchange")} icon="exchange" onPress={this.onPressExchange} />
                        )}
                    <CircleButton title={t("send")} icon="send" onPress={this.onPressSend} />
                    <CircleButton
                        title={t("receive")}
                        icon="receive"
                        onPress={() => this.props.navigation.navigate(WalletNavigatorScreens.Receive.routeName)}
                    />
                    {!this.currency.isTestnet() &&
                        this.currency.isBuyable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.buyService) && (
                            <CircleButton
                                title={t("buy")}
                                icon="cart"
                                onPress={() => this.onPressService(ButtonActionsType.BUY)}
                            />
                        )}
                    {!this.currency.isTestnet() &&
                        this.currency.isSellable() &&
                        ModuleControlService.getInstance().isServiceEnabled(Services.sellService) && (
                            <CircleButton
                                title={t("sell")}
                                icon="dollar"
                                onPress={() => this.onPressService(ButtonActionsType.SELL)}
                            />
                        )}
                </Row>

                {/* {this.currency.getUnderlyingCurrency()?.hasEthCompatibility() && this.state.showBotCard && (
                    <View>
                        <BotCard
                            title={t("ethCompatibility_warning_title")}
                            message={t("ethCompatibility_warning_message")}
                        ></BotCard>
                    </View>
                )} */}
            </View>
        );
    };

    render() {
        return (
            <ScreenWrapper>
                <Header
                    type={HeaderType.Light}
                    {...this.props}
                    title={" "}
                    rightBtn={[
                        {
                            onPress: () => {
                                this.onRefresh();
                            },
                            icon: "refresh",
                        },
                    ]}
                >
                    <View
                        style={{
                            zIndex: 99999,
                        }}
                    >
                        <CurrencyIcon
                            currency={this.currency}
                            iconSize={40}
                            size={60}
                            styles={{
                                top: 8,

                                alignItems: "center",
                                justifyContent: "center",
                                borderColor: colors.gradientFrom,
                                borderWidth: 4,
                            }}
                        />
                    </View>
                </Header>

                <Container style={{ flex: 1, paddingHorizontal: 0 }}>
                    <FlatList
                        data={this.state.transactions}
                        renderItem={this.renderItem}
                        keyExtractor={(item) => item.txId + (Math.random() * 100).toString()}
                        initialNumToRender={10}
                        ListEmptyComponent={this.renderEmptyList}
                        ListHeaderComponent={this.renderListHeader}
                        contentContainerStyle={{ paddingHorizontal: 20 }}
                        refreshControl={
                            <RefreshControl
                                progressViewOffset={-12}
                                tintColor={colors.text}
                                onRefresh={this.onRefresh}
                                refreshing={this.props.syncing || this.state.syncing}
                            />
                        }
                    />
                </Container>
            </ScreenWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return state.wallet;
};

const mapDispatchToProps = (dispatch) => ({});

const WalletScreen = connect(mapStateToProps, mapDispatchToProps)(_WalletScreen);

export default WalletScreen;

const styles = StyleSheet.create({
    buttons: {
        marginTop: 0,
        marginBottom: 15,
        marginHorizontal: -5,
        alignItems: "center",
        justifyContent: "center",
    },
    button: {
        backgroundColor: colors.secondary,
        width: 70,
        height: 70,
        borderRadius: 40,
        justifyContent: "center",
        marginBottom: 8,
    },
    buttonWrapper: {
        alignItems: "center",
    },
    icon: {
        width: 30,
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        paddingRight: 5,
    },
    tag: {
        borderRadius: settings.cardRadius,
        backgroundColor: colors.secondary,
        padding: 5,
    },
    currencyIcon: {
        top: 8,
        width: 60,
        height: 60,
        borderRadius: 30,
        alignItems: "center",
        justifyContent: "center",
        borderColor: colors.gradientFrom,
        borderWidth: 4,
    },
});
