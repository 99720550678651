import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import { connect } from "react-redux";
import i18n from "@i18n/i18n";
import { SeedNavigatorScreens } from "@navigation/SeedNavigator";
import ScannerBase from "./ScannerBase";

interface Props {
    navigation: NavigationType;
    route: any
}

interface State { }

const { t } = i18n;

export default class ScannerSeedScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.executeBarCodeScanned = this.executeBarCodeScanned.bind(this);
        this.navigateToImport = this.navigateToImport.bind(this);
        this.state = {};
    }

    executeBarCodeScanned(qrCode) {
        try {
            const data: [] = qrCode.split(",");

            this.setState({ scanned: true });

            this.navigateToImport(data);
            this.setState({ scanned: false });
        } catch { }
    }

    navigateToImport(data) {
        this.props.navigation.navigate(SeedNavigatorScreens.Import.routeName, { words: data });
    }

    render() {
        return (
            <ScannerBase
                navigation={this.props.navigation}
                returnBarCodeScanned={this.executeBarCodeScanned}
            ></ScannerBase>
        );
    }
}
