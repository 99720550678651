import { WalletConnectDappModel, DappModel } from "@custom-types/DappModel";

export const SET_ALL_DAPPS = "SET_ALL_DAPPS";
export const SET_XO_DAPPS = "SET_XO_DAPPS";
export const SET_WC_DAPPS = "SET_WC_DAPPS";

export const setAllDapps = (
    dapps: Array<DappModel>,
) => {
    return { type: SET_ALL_DAPPS, dapps };
};

export const setXoDapps = (
    dapps: Array<DappModel>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_XO_DAPPS, dapps, paginator };
};

export const setWalletConnectDapps = (
    dapps: Array<WalletConnectDappModel>,
    paginator: { page: number; totalDocs: number }
) => {
    return { type: SET_WC_DAPPS, dapps, paginator };
};
