import { ActivityModel } from "@custom-types/ActivityModel";
import { SET_ACTIVITY } from "@store/actions/activity.actions";
import { RELOAD } from "@store/actions/global";

interface state {
    activity: Array<ActivityModel>;
    paginator: { page: number; totalDocs: number; totalPages: number };
}

const initialState: state = {
    activity: [],
    paginator: { page: 0, totalDocs: 0, totalPages: 0 },
};

const activityReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_ACTIVITY:
            return {
                ...state,
                activity: [...action.activity],
                paginator: action.paginator,
            };
        case RELOAD:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};

export default activityReducer;
