import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ExperiencesRedux } from "@custom-types/ExpercienceModel";
import { StyleSheet, View, ViewStyle } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import { connect } from "react-redux";
import { Client } from "@custom-types/Client";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import FlatListBase from "@base/FlatListBase";
import { SectionTitle } from "@screens/home/components/SectionTitle";
import AssetCard from "./AssetCard";

interface Props {
    navigation: NavigationType;
    containerStyle?: ViewStyle;
    client: Client;
    experiences: ExperiencesRedux;
}

interface State { }

const { t } = i18n;

export class _AssetsSection extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.renderItem = this.renderItem.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.filterAndSortData = this.filterAndSortData.bind(this);
        this.navigateToSection = this.navigateToSection.bind(this);

        this.state = {};
    }

    async componentDidMount() { }

    renderItem = ({ item }) => {
        return <AssetCard asset={item} size={175} {...this.props} />;
    };

    navigateToSection() {
        this.props.navigation.navigate("Experience", {
            screen: ExperienceNavigatorScreens.SectionAsset.routeName,
        });
    }

    async loadMore() { }

    filterAndSortData() {
        return this.props.experiences?.asset?.docs.filter((m) => {
            return !m.archivedAt;
        });
    }

    render() {
        return (
            <View>
                {this.props.experiences?.asset?.paginator?.totalDocs > 0 && (
                    <View style={[{ paddingVertical: 10 }, this.props.containerStyle]}>
                        <SectionTitle
                            onPress={this.navigateToSection}
                            title={"ASSETs"}
                            icon={"🛍️"}
                            counter={this.props.experiences?.asset?.paginator?.totalDocs}
                            seeMore={true}
                            {...this.props}
                        ></SectionTitle>

                        <FlatListBase
                            data={this.filterAndSortData()}
                            renderItem={this.renderItem}
                            keyExtractor={(item) => item._id}
                            contentContainerStyle={{ paddingTop: 10, zIndex: 9999 }}
                            initialNumToRender={2}
                            horizontal={true}
                            maxToRenderPerBatch={5}
                            showsHorizontalScrollIndicator={false}
                            onEndReachedThreshold={1}
                            onEndReached={this.loadMore}
                        ></FlatListBase>
                    </View>
                )}
            </View>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        flex: 1,
        borderRadius: settings.cardRadius,
        marginRight: 10,
        overflow: "hidden",
        backgroundColor: colors.red,
        //height: 180
    },
    bubble: {
        position: "absolute",
        top: -23,
        marginLeft: 3,
        backgroundColor: colors.complementary || colors.secondary,
        paddingLeft: 7,
        paddingRight: 8,
        paddingTop: 1,
        paddingBottom: 2,
        borderRadius: 10,
        borderBottomLeftRadius: 2,
    },

    circle: {
        height: 30,
        width: 30,
        borderRadius: 15,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
        marginTop: -5,
        marginRight: 5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        experiences: state.experiences,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const AssetsSection = connect(mapStateToProps, mapDispatchToProps)(_AssetsSection);

export default AssetsSection;
