import Container from "@base/Container";
import { StyleSheet, View, Keyboard, ViewStyle, Dimensions, Platform } from "react-native";

import { colors, settings } from "@styles/globalStyles";
import RegularTextInput from "@base/RegularTextInput";
import React, { Component, useEffect } from "react";
import RenderHtml, { defaultSystemFonts } from "react-native-render-html";
//REACT NATIVE RENDER HTML DOCS
//https://meliorence.github.io/react-native-render-html/
import Constants from "expo-constants";

interface Props {
    source: string;
    containerStyle?: ViewStyle;
}

interface State { }

const systemFonts = [...Constants.systemFonts, "NunitoRegular", "NunitoBold"];

export default class RenderHTML extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    getWindowWidth() {
        return Dimensions.get("window").width < 600 ? Dimensions.get("window").width - 40 : 560;
    }

    render() {
        return (
            <View style={this.props?.containerStyle}>
                <RenderHtml
                    enableUserAgentStyles={true}
                    systemFonts={systemFonts}
                    tagsStyles={{
                        body: { fontFamily: "NunitoRegular", color: colors.text, fontSize: 16 },
                        p: { marginVertical: 6 },
                        strong: { fontFamily: "NunitoBold", fontWeight: "400" },
                        h1: {
                            fontFamily: "NunitoBold",
                            fontSize: 18,
                            marginVertical: 5,
                            marginTop: 25,
                            fontWeight: "800",
                        },
                        h2: {
                            fontFamily: "NunitoBold",
                            fontSize: 18,
                            marginVertical: 5,
                            marginTop: 25,
                            fontWeight: "400",
                        },
                        h3: {
                            fontFamily: "NunitoBold",
                            fontSize: 18,
                            marginVertical: 5,
                            marginTop: 25,
                            fontWeight: "400",
                        },
                    }}
                    contentWidth={this.getWindowWidth()}
                    source={{ html: this.props.source }}
                />
            </View>
        );
    }
}

const styles = StyleSheet.create({});
