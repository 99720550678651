import { getEnv } from "@utils/helpers/global/global";
import store from "@store/index";
import { maintenanceMode } from "@store/actions/global";
import Constants from "expo-constants";
import axios from "axios";
import { ApiService } from "@core/services/ApiService";
import Wallet from "@core/wallet/Wallet";
import { ProviderType } from "@custom-types/ProviderType";
import OAuthStorage from "@utils/storage/storages/OAuthStorage";
import UpdateService from "./UpdateService";
import i18n from "@i18n/i18n";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import { Platform } from "react-native";

const { t } = i18n;

export enum Modules {
    "walletModule" = "walletModule",
    "socialNetworkModule" = "socialNetworkModule",
    "dappsModule" = "dappsModule",
    "nftsModule" = "nftsModule",
    "poapsModule" = "poapsModule",
    "experiencesModule" = "experiencesModule",
    "blogModule" = "blogModule",
    "bannersModule" = "bannersModule",
}

export enum Services {
    "walletConnectService" = "walletConnectService",
    "exchangeService" = "exchangeService",
    "buyService" = "buyService",
    "sellService" = "sellService",
    "oAuthService" = "oAuthService",
    "testnetService" = "testnetService",
}

export enum SocialNetworkType {
    "close" = "close",
    "open" = "open",
    "exclusive" = "exclusive",
}

export enum ExperienceModuleType {
    "open" = "open",
    "exclusive" = "exclusive",
}

export enum NFTsModules {
    "mint" = "mint",
    "transfer" = "transfer",
    "list" = "list",
    "marketplace" = "marketplace",
}

export enum SkinType {
    "base" = "base",
    "events" = "events",
    "crypto" = "crypto",
    "memberships" = "memberships",
    "sports" = "sports",
    "assets" = "assets",
    "nfts" = "nfts",
    "news" = "news",
}

export class ModuleControlService {
    private static instance: ModuleControlService;
    error: string = "";

    private Modules = {
        walletModule: false,
        socialNetworkModule: false,
        dappsModule: false,
        nftsModule: false,
        poapsModule: false,
        experiencesModule: false,
        blogModule: false,
        bannersModule: false,
    };

    private Services = {
        walletConnectService: false,
        exchangeService: false,
        testnetService: false,
        buyService: false,
        sellService: false,
        oAuthService: false,
    };

    private App = {
        _id: "",
        name: "",
        digitalCurrencies: [],
        fiatCurrencies: [],
        enabled: false,
        socialNetwork: "",
        maintenanceMode: false,
        experienceModuleType: ExperienceModuleType.open,
        skins: SkinType.base,
        nftProps: { mint: true, transfer: true, list: true, marketplace: true },
        providers: [],
        forceUpdate: false,
        shareUrl: "",
        mqttUrl: "",
        wcProjectId: "",
        languages: [],
    };

    static getInstance(): ModuleControlService {
        if (!ModuleControlService.instance) {
            ModuleControlService.instance = new ModuleControlService();
        }
        return ModuleControlService.instance;
    }

    async init() {
        try {
            let headers: any = ApiService.headers();
            const version = Constants.expoConfig.version;

            const auth = await OAuthStorage.get();

            headers.ClientId = auth?.client?._id;
            const url = `${getEnv("API_URL")}app/versions/${version}`;
            const { data } = await axios.get(url, { headers });

            this.Modules = data.modules;

            this.Services = {
                walletConnectService: data.services.walletConnectService,
                exchangeService: data.services.exchangeService,
                buyService: data.services?.buyService,
                sellService: data.services?.sellService,
                oAuthService: data.services.oAuthService,
                testnetService: data.services.testnetService,
            };

            this.App = data.wallet;
            this.App.skins = data.wallet?.skinType || SkinType.base;

            this.App.mqttUrl = data?.settings?.mqttUrl || "",

            // this.App.wcProjectId = "4f4f9faa9d5c267b2fab33d1478be571"

            UpdateService.getInstance().getUpdates(this.App.forceUpdate);
            return this.handleResponse();
        } catch (e: any) {
            return this.handleResponse(true, e?.response?.data?.message);
        }
    }

    handleResponse(error?, message?: string) {
        if (error) {
            this.error = message?.length ? `"${trimHelper(message, 80, true)}"` : "";
            return false;
        }
        if (this.App?.digitalCurrencies?.length == 0) {
            this.error = `"CFG001 (${Platform.OS.toUpperCase()} - ${Constants.expoConfig.version})"`;
            return false;
        }

        if (this.App?.fiatCurrencies?.length == 0) {
            this.error = `"CFG002 (${Platform.OS.toUpperCase()} - ${Constants.expoConfig.version})"`;
            return false;
        }

        return true;
    }

    getErrorMessage() {
        return this.error;
    }

    getLanguages() {
        return this.App.languages;
    }

    setEnabledCurrencies() {
        if (this.App.digitalCurrencies?.length) {
            Wallet.getInstance().setEnabledCurrencies(this.getCurrencies());
        }
    }

    getProviders() {
        let providers = this.App.providers?.map((p) => {
            return p.provider;
        });
        return providers;
    }

    getShareUrl() {
        return this.App.shareUrl;
    }

    getMqttUrl() {
        return this.App.mqttUrl;
    }

    getWalletConnectProjectId() {
        return this.App.wcProjectId;
    }

    getProvider(provider: ProviderType) {
        return this.App.providers?.find((p) => {
            return (provider = p?.provider);
        });
    }

    getCurrencies() {
        return this.App.digitalCurrencies;
    }

    getFiatCurrencies() {
        return this.App.fiatCurrencies;
    }

    appInMaintenance() {
        let maintenance = this.App.maintenanceMode;
        if (maintenance) {
            store.dispatch(maintenanceMode());
        }

        return maintenance;
    }

    isModuleEnabled(module: Modules) {
        try {
            switch (module) {
                case Modules.poapsModule:
                    return Wallet.getInstance().isTestnet() ? false : this.Modules[module];

                default:
                    return this.Modules[module];
            }
        } catch (e) {}
        return false;
    }
    isServiceEnabled(service: Services) {
        try {
            return this.Services[service];
        } catch (e) {}
        return false;
    }

    getActiveExperienceModuleType(experienceModuleType) {
        try {
            return this.App.experienceModuleType == experienceModuleType;
        } catch (e) {}
    }

    getSocialNetworkType() {
        try {
            return this.App.socialNetwork;
        } catch (e) {}
    }

    isNecessaryUserVerified(verified) {
        try {
            if (!this.Modules.socialNetworkModule) {
                return false;
            }
            return this.App.socialNetwork == SocialNetworkType.exclusive ? verified : true;
        } catch (e) {}
    }

    isNFTsModuleEnabled(nftsModule: NFTsModules) {
        if (!this.Modules.nftsModule) return false;

        return this.App.nftProps[nftsModule] || false;
    }

    getDefaultScreenCompatibility() {
        const defaultScreen = Constants.expoConfig?.extra?.defaultScreen || "";
        switch (defaultScreen) {
            case "NFTs":
                return this.isModuleEnabled(Modules.nftsModule) ? defaultScreen : "";

            case "Dapps":
                return this.isModuleEnabled(Modules.dappsModule) ? defaultScreen : "";

            case "SocialNetwork":
                return this.isModuleEnabled(Modules.socialNetworkModule) ? defaultScreen : "";

            case "Experiences":
                return this.isModuleEnabled(Modules.experiencesModule) ? defaultScreen : "";
            default:
                return "";
        }
    }

    getSkin = (skins: SkinType) => {
        try {
            return this.App.skins == skins;
        } catch (e) {}
    };
}
