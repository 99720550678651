import { ActivityModel } from "@custom-types/ActivityModel";

export const SET_ACTIVITY = "SET_ACTIVITY";

export const setActivity = (
    activity: Array<ActivityModel>,
    paginator: { page: number; totalDocs: number; totalPages: number }
) => {
    return { type: SET_ACTIVITY, activity, paginator };
};
