import { FiatCurrency } from "@core/fiat/FiatCurrency";
import { Client } from "./Client";
import Currency from "@core/currencies/Currency";

export enum RedeemableType {
    "TICKET" = "ticket",
    "VOUCHER" = "voucher",
}

export enum ExperienceStatus {
    "CREATED" = "created",
    "ASSIGNED" = "assigned",
    "TRANSFERED" = "transfered",
    "USED" = "used",
    "EXPIRED" = "expired",
}

export interface ExperiencesRedux {
    membership: {
        docs: Array<MembershipModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    ticket: {
        docs: Array<RedeemableModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    voucher: {
        docs: Array<RedeemableModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    asset: {
        docs: Array<AssetModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    experiences: {
        docs: Array<ExperienceModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    merchants: {
        docs: Array<MerchantModel>;
        paginator: { page: number; totalDocs: number; totalPages: number };
    };
    selectedRedeemable?: RedeemableModel;
    selectedMerchant?: MerchantModel;
    checkoutRedeemables?: Array<CartRedeemable>;
    selectedFiatCurrency: FiatCurrency;
    selectedCurrency: FiatCurrency | Currency
}

export interface RedeemableModel {
    _id: string;
    experience?: ExperienceModel;
    merchant?: MerchantModel;
    redeemableOption?: RedeemableOptionModel
    history?: Array<RedeemableHistory>;
    status: ExperienceStatus;
    favorite?: boolean;
    archivedAt?: Date | string;
    redeemed: boolean;
    owner?: Partial<Client>;
    price?: number;
}

export interface RedeemableOptionModel {
    _id: string;
    experience: string;
    name: string;
    description: string;
    image?: { cover?: string; thumbnail?: string; carousel?: string };
    transferable?: boolean;
    type: RedeemableType;
    limitedQuantity?: boolean;
    quantity?: number;
    startDate: Date | string;
    endDate: Date | string;
    specificDates: boolean;
    buyable: boolean;
    prices?: any
}

export interface AssetModel {
    _id: string;
    assetOption?: {
        _id: string;
        name: string;
        description: string;
        image?: { cover: string; thumbnail: string; carousel: string };
        transferable?: boolean;
        limitedQuantity?: boolean;
        quantity?: number;
        properties?: Array<{
            property: { _id: string; name: string };
            value: {
                _id: string;
                name: string;
            };
        }>;
    };
    merchant?: MerchantModel;
    history?: Array<AssetHistory>;
    status: ExperienceStatus;
    favorite?: boolean;
    archivedAt?: Date | string;
    owner?: Partial<Client>;
}

export interface MembershipModel {
    _id: string;
    membershipOption?: {
        _id: string;
        merchant: string;
        name: string;
        description: string;
        image?: { cover: string; thumbnail: string; carousel: string };
    };
    merchant?: MerchantModel;
    favorite?: boolean;
    archivedAt?: Date | string;
    owner?: Partial<Client>;
}

export interface RedeemableHistory {
    _id: string;
    action: ExperienceStatus;
    createdAt?: Date | string;
    currentOwner: Partial<Client>;
    currentStatus: ExperienceStatus;
    previousOwner: Partial<Client>;
    data?: any;
    gate?: {
        enabled: boolean;
        images: Array<{ cover: string; thumbnail: string }>;
        merchant: string;
        name: string;
        _id: string;
    };
    previousStatus: ExperienceStatus;
    redeemable: string;
}

export interface AssetHistory {
    _id: string;
    action: ExperienceStatus;
    createdAt?: Date | string;
    currentOwner: Partial<Client>;
    currentStatus: ExperienceStatus;
    previousOwner: Partial<Client>;
    previousStatus: ExperienceStatus;
    asset: string;
}
export interface MerchantModel {
    _id: string;
    name: string;
    wallet?: string;
    image?: { cover?: string; thumbnail?: string; carousel?: string };
    experiences?: Array<string>;
    gates?: Array<string>;
    memberships?: Array<string>;
    membershipOptions?: Array<string>;
    verifiers?: Array<string>;
    isAccessRestricted?: boolean;
    redeemableDropRules?: Array<string>;
    gateways: Array<GatewaysModel>;
    assets: Array<number>;
    assetOptions: Array<string>;
    assetProperties: Array<string>;
}

export interface ExperienceModel {
    _id: string;
    name: string;
    description: string;
    image?: { cover: string; thumbnail: string; carousel: string };
    location?: { description: string, lat: number, lng: number };
    startDate: Date | string;
    endDate: Date | string;
    merchant: MerchantModel;
    availableDigitalCurrencies: Array<ExperienceCurrency>;
    availableFiatCurrencies: Array<ExperienceCurrency>;
    redeemableOptions?: Array<RedeemableOptionModel>;
    redeemables?: number;
    type: string;
    wallet?: string;
}

export interface GateModel {
    _id: string;
    name: string;
    images?: { cover: string; thumbnail: string };
    enabled: boolean;
    merchant: MerchantModel;
}

export interface CartRedeemable {
    quantity: number;
    redeemable: RedeemableOptionModel;
}

export interface ExperienceSearchParams {
    name?: string,
    startDate?: string,
    endDate?: string,
    merchant?: string,
}

export interface ExperienceCurrency {
    currencyId: string;
    gatewayId: string;
}

export interface GatewaysModel {
    apiKey: string;
    gateway: GatewayModel;
    merchant: string;
    _id: string;
}

export interface GatewayModel {
    digitalCurrencies: Array<any>
    enabled: boolean
    fiatCurrencies: Array<any>
    name: string
    provider: string;
    _id: string
}