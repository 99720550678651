import React, { Component } from "react";
import i18n from "@i18n/i18n";
import RegularText from "@base/RegularText";
import { StyleSheet, View } from "react-native";
import BoldText from "@base/BoldText";
import { Image } from "react-native";
import { colors } from "@styles/globalStyles";
import Row from "@base/Row";

interface Props {}

interface State {}

const { t } = i18n;

export default class CustomWelcomeContent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <View style={styles.wrapper}>
                <Image
                    resizeMode="cover"
                    source={require("../../assets/splash/splash.png")}
                    style={{ width: "160%", height: 250, marginTop: 105, marginBottom: -15 }}
                />

                <View style={{ alignItems: "center" }}>
                    <RegularText align="center" fontSize={25}>
                        Crypto made{" "}
                        <BoldText align="center" fontSize={25}>
                            simple
                        </BoldText>
                        <RegularText fontSize={5}> </RegularText>
                        <BoldText fontSize={55} color={colors.secondary}>
                            .
                        </BoldText>
                    </RegularText>
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "space-between",
    },
    wrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
});
