import BoldText from "@base/BoldText";
import Container from "@base/Container";
import FlatListBase from "@base/FlatListBase";
import InlineButton from "@base/InlineButton";
import RegularText from "@base/RegularText";
import Row from "@base/Row";
import SemiBoldText from "@base/SemiBold";
import AvatarBase from "@components/avatar/AvatarBase";
import Header from "@components/header/Header";
import Icon from "@components/icons";
import ImageBase from "@components/image/ImageBase";
import LeafletComponent from "@components/maps/Leaflet";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import Currency from "@core/currencies/Currency";
import { FiatCurrency } from "@core/fiat/FiatCurrency";
import ExperienceService from "@core/services/ExperienceService";
import { ModuleControlService } from "@core/services/ModuleControlService";
import ShareService from "@core/services/ShareService";
import Wallet from "@core/wallet/Wallet";
import { Client } from "@custom-types/Client";
import { CartRedeemable, ExperienceModel, RedeemableOptionModel } from "@custom-types/ExpercienceModel";
import { HeaderType } from "@custom-types/HeaderType";
import { NavigationType } from "@custom-types/NavigationType";
import i18n from "@i18n/i18n";
import { ExperienceNavigatorScreens } from "@navigation/ExperienceNavigator";
import { Route } from "@react-navigation/native";
import {
    emptyExperienceCurrency,
    emptyRedeemableCart,
    setExperienceCurrency,
    setReedemableCart,
} from "@store/actions/experiences.actions";
import { hidePopupMessage, ready, showModalBottom, showPopupMessage, showSnackbar } from "@store/actions/global";
import store from "@store/index";
import { colors, settings } from "@styles/globalStyles";
import { trimHelper } from "@utils/helpers/chat/chat.helper";
import moment from "moment";
import React, { Component } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { connect } from "react-redux";
import RedeemableDetailModal from "./components/RedeemableDetailModal";
import RedeemableOptionCard from "./components/RedeemableOptionCard";
import SelectCurrencyModal from "./components/SelectCurrencyModal";
import RenderHTML from "@components/renderHTML/RenderHTML";

interface Props {
    navigation: NavigationType;
    client: Client;
    route: Route<string, { experience: ExperienceModel }>;
    checkoutRedeemables: Array<CartRedeemable>;
    selectedCurrency: { currency: Currency | FiatCurrency; gatewayId: string };
    fiatCurrency: FiatCurrency;
}

interface State {
    experience: ExperienceModel;
    redirectUrl: string;
    redeemablesData: Array<CartRedeemable>;
    showBuyButton: boolean;
}

const { t } = i18n;
export class _ExperienceScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            experience: this.props.route?.params?.experience || null,
            redeemablesData: this.props.checkoutRedeemables || [],
            showBuyButton: false,
            redirectUrl:
                `${ModuleControlService.getInstance().getShareUrl()}?type=experience&id=${this.props.route?.params?.experience._id
                }` || "",
        };
    }

    async componentDidMount() {
        const experience = await ExperienceService.getInstance().getExperience(
            this.props.route.params?.experience?._id
        );



        if (experience) {
            this.setState({ experience });
            store.dispatch(ready());
        }

        const setBuyable = this.state.experience.redeemableOptions.filter((item: RedeemableOptionModel) => {
            // Check buyable and end date
            if (!item.buyable) return false;
            const endDate = new Date(item.endDate);
            const now = new Date();
            return endDate >= now;
        });

        const setRedeemable = setBuyable.map((item: RedeemableOptionModel) => {
            return { redeemable: item, quantity: 0 };
        });

        store.dispatch(ready());
        store.dispatch(setReedemableCart(setRedeemable));

        if (!this.props.selectedCurrency) {
            const availableFiat: any = this.checkFiatWallet();

            if (availableFiat) {
                store.dispatch(
                    setExperienceCurrency({
                        currency: Wallet.getInstance().findFiatCurrencyById(availableFiat.currencyId),
                        gatewayId: availableFiat.gatewayId,
                    })
                );
                return;
            }

            if (this.state.experience.availableFiatCurrencies.length) {
                store.dispatch(
                    setExperienceCurrency({
                        currency: Wallet.getInstance().findFiatCurrencyById(
                            this.state.experience.availableFiatCurrencies[0].currencyId
                        ),
                        gatewayId: this.state.experience.availableFiatCurrencies[0].gatewayId,
                    })
                );
                return;
            }

            if (this.state.experience.availableDigitalCurrencies.length) {
                store.dispatch(
                    setExperienceCurrency({
                        currency: Wallet.getInstance().findCurrencyById(
                            this.state.experience.availableDigitalCurrencies[0].currencyId
                        ),
                        gatewayId: this.state.experience.availableDigitalCurrencies[0].gatewayId,
                    })
                );
                return;
            }
        }
    }

    checkFiatWallet = () => {
        const fiat = this.state.experience.availableFiatCurrencies.find((item) => {
            if (item.currencyId == this.props.fiatCurrency.getId()) {
                return item;
            }
        });

        return fiat || false;
    };

    componentDidUpdate(prevProps) {
        if (prevProps.checkoutRedeemables !== this.props.checkoutRedeemables) {
            this.setState({ redeemablesData: this.props.checkoutRedeemables });

            const quantity = this.props.checkoutRedeemables?.filter((r: CartRedeemable) => {
                return r.quantity > 0;
            });
            this.setState({ showBuyButton: quantity?.length > 0 });
        }
    }

    renderRedeemable = ({ item }) => {


        return item.redeemable?.buyable && this.props.selectedCurrency?.gatewayId ? (
            <RedeemableOptionCard
                quantity={item.quantity}
                redeemable={item.redeemable}
                onPress={() => this.redeemableDetail(item.redeemable)}
                fromExperience={true}
                currency={this.props.selectedCurrency.currency}
            />
        ) : null;
    };

    redeemableDetail = (redeemable: RedeemableOptionModel) => {
        store.dispatch(
            showModalBottom({
                modalContent: <RedeemableDetailModal navigation={this.props.navigation} redeemable={redeemable} />,
            })
        );
    };

    handleShare = async () => {
        ShareService.getInstance().handleShare(t("check_experience"), this.state.redirectUrl);
    };

    checkQuantity = (redeemables: Array<CartRedeemable>) => {
        const findItem = redeemables.find((item) => item.quantity > 0);

        return findItem ? true : false;
    };

    handleBackButton = async () => {
        if (this.checkQuantity(this.props.checkoutRedeemables)) {
            store.dispatch(
                showPopupMessage({
                    type: "MESSAGE",
                    message: t("confirm_go_back"),
                    onPressAccept: () => {
                        store.dispatch(emptyRedeemableCart());
                        store.dispatch(emptyExperienceCurrency());
                        store.dispatch(hidePopupMessage());
                        this.props.navigation.goBack();
                    },
                })
            );
        } else {
            this.props.navigation.goBack();
        }
    };

    checkout = () => {
        if (this.checkQuantity(this.props.checkoutRedeemables) && this.props.selectedCurrency) {
            this.props.navigation.navigate("Experience", {
                screen: ExperienceNavigatorScreens.Checkout.routeName,
                params: { experience: this.state.experience },
            });
        } else {
            store.dispatch(showSnackbar({ type: "MESSAGE", message: t("complete_field_warning") }));
        }
    };

    getDate = (start: Date | string, end: Date | string) => {
        const startDate = moment(start);
        const endDate = moment(end);

        if (startDate.isSame(endDate, "day")) {
            return startDate.format("LL");
        }
        if (startDate.isSame(endDate, "month")) {
            return `${startDate.format("MMMM D")} ${t("to").toLowerCase()} ${endDate.format("D")}, ${startDate.format("YYYY")}`;
        }
        if (startDate.isSame(endDate, "year")) {
            return `${startDate.format("MMMM D")} ${t("to").toLowerCase()} ${endDate.format("MMMM D")}, ${startDate.format("YYYY")}`;
        }
        return `${startDate.format("LL")} ${t("to").toLowerCase()} ${endDate.format("LL")}`;
    };

    showSelectCurrency() {
        store.dispatch(
            showModalBottom({
                modalContent: (
                    <SelectCurrencyModal
                        fiatCurrency={this.state.experience.availableFiatCurrencies}
                        currencies={this.state.experience.availableDigitalCurrencies}
                    />
                ),
            })
        );
    }

    getName = () => {
        const types = this.state.redeemablesData.map((item: CartRedeemable) => item.redeemable.type)
        const dataArr = new Set(types);
        const filter = [...dataArr]
        return filter.toString().split(",").join(" ")
    }

    render() {
        return (
            <ScreenWrapper>
                <Header
                    title={trimHelper(this.state.experience.name, 15)}
                    type={HeaderType.Light}
                    leftBtn={{
                        onPress: () => {
                            this.handleBackButton();
                        },
                        icon: "arrow-left",
                        size: 24,
                    }}
                    rightBtn={
                        this.state.experience?.availableFiatCurrencies?.length +
                            this.state.experience?.availableDigitalCurrencies?.length >
                            1
                            ? [
                                {
                                    onPress: () => {
                                        this.showSelectCurrency();
                                    },
                                    icon: "currency-exchange",
                                    size: 28,
                                },
                                {
                                    onPress: () => {
                                        this.handleShare();
                                    },
                                    icon: "share",
                                    size: 22,
                                },
                            ]
                            : [
                                {
                                    onPress: () => {
                                        this.handleShare();
                                    },
                                    icon: "share",
                                    size: 22,
                                },
                            ]
                    }
                    {...this.props}
                />

                {this.state.experience && (
                    <ScrollView>
                        <Container style={{ flex: 1 }}>
                            <View style={styles.card}>
                                <View style={styles.wrapper}>
                                    <View style={styles.container}>
                                        <ImageBase
                                            autoSize={true}
                                            uri={
                                                this.state.experience?.image?.carousel ||
                                                this.state.experience?.image?.cover ||
                                                ""
                                            }
                                            style={styles.image}
                                        ></ImageBase>
                                    </View>
                                    <View style={styles.circleWrapper}>
                                        <View style={styles.circle}>
                                            <AvatarBase
                                                uri={this.state.experience?.merchant?.image?.thumbnail}
                                                size={30}
                                            ></AvatarBase>
                                        </View>
                                    </View>

                                    <View style={styles.details}>
                                        {this.state?.experience?.name && (
                                            <BoldText
                                                style={{ position: "relative", marginBottom: 20, marginTop: 5 }}
                                                fontSize={26}
                                            >
                                                {this.state.experience.name}
                                            </BoldText>
                                        )}

                                        <View style={[styles.divider, styles.section, { paddingTop: 0 }]}>
                                            {this.state?.experience?.startDate && (
                                                <Row
                                                    style={{
                                                        paddingTop: 10,
                                                        alignItems: "center",
                                                        marginBottom: 15,
                                                    }}
                                                >
                                                    <View style={styles.icon}>
                                                        <Icon
                                                            color={colors.text}
                                                            size={15}
                                                            name="calendar-minus"
                                                        ></Icon>
                                                    </View>
                                                    <RegularText fontSize={14} style={{ marginLeft: 10 }}>
                                                        {this.getDate(
                                                            this.state.experience?.startDate,
                                                            this.state.experience?.endDate
                                                        )}
                                                    </RegularText>
                                                </Row>
                                            )}
                                            {this.state.experience?.description?.length && (
                                                <RenderHTML
                                                    containerStyle={{}}
                                                    source={this.state.experience.description}
                                                ></RenderHTML>
                                            )}
                                        </View>

                                        {this.props.selectedCurrency?.gatewayId &&
                                            this.state.redeemablesData.length > 0 && (
                                                <View style={[styles.divider, styles.section]}>
                                                    <SemiBoldText style={{ marginBottom: 10, textTransform: "capitalize" }}>{this.getName()}</SemiBoldText>
                                                    <FlatListBase
                                                        data={this.state.redeemablesData}
                                                        renderItem={(item) => this.renderRedeemable(item)}
                                                        keyExtractor={(item) => item.redeemable._id}
                                                    />
                                                    {this.state.showBuyButton && (
                                                        <InlineButton
                                                            style={{ marginTop: 10 }}
                                                            onPress={this.checkout}
                                                            title={t("continue")}
                                                        />
                                                    )}
                                                </View>
                                            )}

                                        {this.state?.experience?.location && (
                                            <View style={styles.section}>
                                                <SemiBoldText style={{ marginBottom: 10 }}>{t('location')}</SemiBoldText>
                                                {this.state.experience?.location?.description && (
                                                    <Row style={{ paddingTop: 10, alignItems: "center" }}>
                                                        <View style={styles.icon}>
                                                            <Icon color={colors.text} size={16} name="address"></Icon>
                                                        </View>
                                                        <RegularText fontSize={14} style={{ marginLeft: 10, flex: 1 }}>
                                                            {this.state.experience.location.description}
                                                        </RegularText>
                                                    </Row>
                                                )}

                                                {this.state.experience.location.lng &&
                                                    this.state.experience.location.lat && (
                                                        <LeafletComponent
                                                            marker={[
                                                                this.state.experience.location.lat,
                                                                this.state.experience.location.lng,
                                                            ]}
                                                            markerImage={
                                                                this.state.experience.merchant.image.thumbnail ||
                                                                this.state.experience.merchant.image.cover
                                                            }
                                                        />
                                                    )}
                                            </View>
                                        )}
                                    </View>
                                </View>
                            </View>
                        </Container>
                    </ScrollView>
                )}
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    card: {
        backgroundColor: colors.shadow,
        borderRadius: settings.cardRadius,
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 10,
    },
    wrapper: {
        width: "100%",
        paddingHorizontal: 0,
        paddingVertical: 0,
        marginBottom: 10,
    },
    details: {
        width: "100%",
        paddingVertical: 10,
        paddingHorizontal: 15,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
    },
    circleWrapper: {
        position: "absolute",
        top: 15,
        left: 15,
    },
    circle: {
        height: 32,
        width: 32,
        borderRadius: 16,
        backgroundColor: colors.tertiaryShadow,
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        backgroundColor: colors.tertiaryShadow,
        height: 30,
        width: 30,
        borderRadius: 15,
        overflow: "hidden",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    container: {
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-end",
    },
    image: {
        width: "100%",
        height: "100%",
        borderTopLeftRadius: settings.cardRadius,
        borderTopRightRadius: settings.cardRadius,
    },
    avatarMerchant: {
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: colors.complementary || colors.secondary,
        paddingHorizontal: 6,
        paddingVertical: 4,
        borderRadius: 20,
    },
    section: {
        paddingVertical: 25,
        width: "100%",
    },
    divider: {
        borderBottomColor: colors.grey,
        borderBottomWidth: 0.5,
    },
});

const mapStateToProps = (state) => {
    return {
        client: state.auth.client,
        checkoutRedeemables: state.experiences.checkoutRedeemables,
        experiences: state.experiences,
        selectedCurrency: state.experiences.selectedCurrency,
        fiatCurrency: state.wallet.fiatCurrency,
    };
};

const mapDispatchToProps = (dispatch) => ({});

const ExperienceScreen = connect(mapStateToProps, mapDispatchToProps)(_ExperienceScreen);

export default ExperienceScreen;
