import React, { Component } from "react";
import { NavigationType } from "@custom-types/NavigationType";
import ScreenWrapper from "@components/wrapper/ScreenWrapper";
import i18n from "@i18n/i18n";
import Container from "@base/Container";
import RegularText from "@base/RegularText";
import { ScrollView, StyleSheet, View } from "react-native";
import { colors, settings } from "@styles/globalStyles";
import Row from "@base/Row";
import Icon from "@components/icons";
import BoldText from "@base/BoldText";
import { HeaderType } from "@custom-types/HeaderType";
import { Header } from "@components/header/Header";
import { LoginNavigatorScreens } from "@navigation/LoginNavigator";
import { AuthNavigatorScreens } from "@navigation/AuthNavigator";
import SeedHistoryList from "@components/history/SeedHistoryList";
import PressableBase from "@base/PressableBase";

interface Props {
    navigation: NavigationType;
}

interface State {

}

const { t } = i18n;

export default class IncognitoScreen extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onPressImport = this.onPressImport.bind(this);
        this.onPressCreate = this.onPressCreate.bind(this);

    }

    componentDidMount() {

    }

    onPressImport() {
        this.props.navigation.navigate(LoginNavigatorScreens.ImportSeed.routeName);
    }

    onPressCreate() {
        this.props.navigation.navigate(LoginNavigatorScreens.NewSeed.routeName);
    }

    render() {
        return (
            <ScreenWrapper>
                <Header type={HeaderType.Light} {...this.props} />
                <ScrollView style={{ height: 200 }}>
                    <Container style={styles.container}>
                        <View style={styles.wrapper}>
                            <BoldText fontSize={22} style={{ marginBottom: 10 }}>{t('incognito_with_seed_phrase')}</BoldText>
                            <RegularText align="center" fontSize={16}>
                                {t("seed_phrase_detail")}
                            </RegularText>
                        </View>
                        <View style={styles.buttonsWrapper}>
                            <PressableBase onPress={this.onPressCreate}>
                                <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                    <Icon size={20} color={colors.white} name="person-plus"></Icon>
                                    <RegularText style={{ paddingLeft: 10 }} color={colors.white}>
                                        {t('create')} <BoldText color={colors.white}>{t('new_user')}</BoldText>
                                    </RegularText>
                                </Row>
                            </PressableBase>
                            <PressableBase onPress={this.onPressImport}>
                                <Row style={[styles.button, { backgroundColor: colors.secondary }]}>
                                    <Icon size={20} color={colors.white} name="person-check"></Icon>
                                    <RegularText style={{ paddingLeft: 10 }} color={colors.white}>
                                        {t('import')} <BoldText color={colors.white}>{t('seed_phrase')}</BoldText>
                                    </RegularText>
                                </Row>
                            </PressableBase>

                        </View>
                        <View style={{ width: "100%", paddingTop: 20 }}>
                            <SeedHistoryList navigation={this.props.navigation} />
                        </View>
                    </Container>
                </ScrollView>
            </ScreenWrapper>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    wrapper: {
        marginTop: 50,
        alignItems: "center",
        width: "100%",
        paddingBottom: 50
    },
    buttonsWrapper: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",

    },
    button: {
        minWidth: 230,
        padding: 10,
        alignItems: "center",
        textAlignVertical: "center",
        alignSelf: "center",
        textAlign: "center",
        borderRadius: settings.mainButtonsRadius || 25,
        overflow: "hidden",
        marginVertical: 5,
    },
});
