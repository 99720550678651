import React, { Component } from "react";
import SendScreen from "@screens/wallet/send/SendScreen";
import ConfirmSendScreen from "@screens/wallet/send/ConfirmSendScreen";
import NavigationInstantiator from "./helpers/NavigationInstantiator";
import ScannerAddressScreen from "@screens/scanner/ScannerAddressScreen";
import FindClientScreen from "@screens/wallet/send/FindClientScreen";

const SendNavigatorScreens = {
    SendMain: {
        routeName: "SendMain",
        title: "Send",
        component: SendScreen,
    },
    ConfirmSend: {
        routeName: "ConfirmSend",
        title: "Confirm Send",
        component: ConfirmSendScreen,
    },
    FindClient: {
        routeName: "FindClient",
        title: "Find Client",
        component: FindClientScreen,
    },
    Scanner: {
        routeName: "Scanner",
        title: "Scanner",
        component: ScannerAddressScreen,
    },
};

const SendNavigator = NavigationInstantiator(SendNavigatorScreens);

export { SendNavigatorScreens, SendNavigator };
